import React, { useCallback, useEffect, useMemo, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { compose } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import { PROJECT_TYPE_COMPLEX_BUILDINGS } from "../../../common/projects/trackProjects.js";
import * as issueStates from "../../../common/issues/issueStates.js";
import { lokiInstance } from "../../../common/configureMiddleware";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import {
  LabelImportantRounded,
  CloseRounded,
  CheckRounded,
} from "@material-ui/icons";
import * as permissionsFunc from "../../../common/permissions/funcs";
import {
  setDefaultTagSelected,
  isPostInValid,
  createPost,
  updatePost,
  updateIssueState,
  updateIssueAssignTo,
  areAllOtherConnectedPosts_withWantedStatus,
  createLocalPost,
  getNewPostId,
  deletePost,
} from '../../../common/posts/funcs';
import { hideLoading, startLoading, startToast } from "../../../common/app/actions";
import { onDraftModeChange, draftValidator } from "../../../common/ui/actions";
import User from "../../components/CementoComponents/User";
import { GridContainer, GridItem } from "../../components";
import DisplayName from "../../components/CementoComponents/DisplayName";
import Comment from "./Comment";
import {
  createLocalComment, uploadComment,
} from "../../../common/comments/funcs";
import { deleteComment } from "../../../common/comments/funcs";
import { exportPostsPDF } from "../../../common/posts/actions";
import { upsertChecklistItemInstance } from "../../../common/checklistItemsInstances/funcs";
import * as cliStatus from "../../../common/checklistItemsInstances/clItemInstanceStatus.js";
import { uploadImage } from "../../../common/images/actions";
import propertiesMessages from "../../../common/propertiesTypes/propertiesMessages";
import safetyMessages from "../../../common/safety/safetyMessages";
import issuesMessages from "../../../common/issues/issuesMessages";
import postsMessages from "../../../common/posts/postsMessages";
import systemMessages from "../../../common/app/systemMessages";
import newPostMessages from "../../../common/posts/newPostMessages";
import checklistItemMessages from "../../../common/checklistItems/checklistItemMessages";
import Text from "../../components/CementoComponents/Text";
import theme from "../../assets/css/theme";
import trash from "../../assets/img/icons/trash.png";
import NewTaskIcon from "../../assets/img/tasks/newtask.png";
import CommentIcon from "../../assets/img/tasks/comment.png";
import StatusUpdatedIcon from "../../assets/img/tasks/statusUpdated.png";
import actionClose from "../../assets/img/tasks/actionClose.png";
import actionComment from "../../assets/img/tasks/actionComment.png";
import actionReassign from "../../assets/img/tasks/actionReassign.png";
import actionReopen from "../../assets/img/tasks/actionReopen.png";
import PhotoIcon from "../../assets/img/tasks/photo.png";
import Date from "../../components/CementoComponents/Date";
import InputField from "../../components/CementoComponents/InputField";
import SelectableUsers from "../../components/CementoComponents/SelectableUsers";
import ImageCarousel from "../../components/CementoComponents/ImageCarousel";
import MenuScrollbar from "../../components/CementoComponents/MenuScrollbar";
import PdfIcon from "../../assets/img/icons/pdf.png";
import { getLocationTitle } from "../Locations/funcs";
import _ from "lodash";
import { getRelevantPropertyIds } from "../../../common/propertiesInstances/funcs";
import { POST_INFO_SUBJECT_NAME, POST_TYPES_SUB_GROUP_TAG, SAFETY_TRADE_ID } from "../../../common/app/constants";
import { getFullLocationDetailsByIdNoProps } from "../../../common/locations/func";
import postsMenuMessages from '../../../common/posts/postsMenuMessages';
import usePosts from '../../../common/posts/hooks/usePosts';
import { getPostGroupId, getPropTypeByTag } from "../../../common/propertiesTypes/funcs";
import PostProperties from "./PostProperties";
import TradeBadge from "../../components/CementoComponents/TradeBadge";
import ImageTileList from '../../components/ImageTileList/ImageTileList';
import StandardInput from '../../components/CementoComponents/StandardInput';
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import moment from 'moment';
import {withChecklistItemInstances} from "../../../common/posts/hooks/useChecklistItemInstances";
import withRouterHOC from "../../components/Router/util/withRouterHOC.js";

const severityColors = [
  { val: 3, color: "rgba(255,0,0,1)" },
  { val: 2, color: "rgba(255,179,0, 1)" },
  { val: 1, color: "rgba(129, 199, 132, 1)" },
];
const POST_DEFAULT_SEVERITY = 2;
const storyTypes = {
  new: 1,
  comment: 2,
  commentWithImage: 3,
  statusUpdated: 4,
  attachedFile: 5
};
const storyTypesImages = {
  1: NewTaskIcon,
  2: CommentIcon,
  3: PhotoIcon,
  4: StatusUpdatedIcon,
  5: PhotoIcon,
};
class Post extends React.PureComponent {
  constructor(props) {
    super(props);
    this.lokiPostsListener = this.lokiPostsListener.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.onEnterEditMode = this.onEnterEditMode.bind(this);

    let mode = props.getNested(["post", "mode"]);
    this.state = { mode, post: props.post };
  }

  componentWillUnmount() {
    this.lokiPosts.cementoOff("postPageListener");
  }

  UNSAFE_componentWillMount() {
    this.lokiPosts = lokiInstance.getCollection("posts");
    this.lokiPosts.cementoOn("postPageListener", this.lokiPostsListener);
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { changeEditMode = () => { }, selectedProjectId: projectId } = nextProps;

    let newStateChanges = {};
    let newSideCardEditMode = null;
    let newSideCardCreationMode = null;

    if (Boolean(props.editMode) !== Boolean(nextProps.editMode)) {
      newStateChanges.mode = nextProps.editMode ? "draft" : null;
    }
    if (
      props.isValDiff(nextProps, ['post', 'id']) ||
      props.isValDiff(nextProps, ['post']) ||
      props.isValDiff(nextProps, ['location', 'search'])
    ) {
      let nextPost = nextProps.post;

      const postTypeProperty = getPropTypeByTag({ projectId, subjectName: POST_INFO_SUBJECT_NAME, tag: POST_TYPES_SUB_GROUP_TAG });
      const isMultiTypesPostConfigured = !_.isEmpty(postTypeProperty);
      if (isMultiTypesPostConfigured) {
        newStateChanges.postCreationParams = this.getPostCreationParams(nextProps);
      }

      if (!nextProps.getNested(['post', 'id']))
        newSideCardCreationMode = true;

      newStateChanges.post = nextPost;
      if (nextPost && nextPost.mode === "draft") {
        newSideCardEditMode = true;
        newStateChanges.mode = "draft";
      } else {
        newStateChanges.mode = null;
        if (nextProps.editMode) newSideCardEditMode = false;
      }
    }

    if (Object.keys(newStateChanges).length)
      this.setState(newStateChanges, () => {
        if (
          (nextProps.editMode !== newSideCardEditMode &&
            newSideCardEditMode !== null) ||
          newSideCardCreationMode !== null
        )
          changeEditMode(newSideCardEditMode, Boolean(newSideCardCreationMode));
      });
  }

  getPostCreationParams(nextProps) {
    const { location, post } = nextProps || this.props;
    const searchParams = new URLSearchParams(location.search);
    const groupId = searchParams.get('groupId') || getPostGroupId(post)
    const subGroupId = searchParams.get('subGroupId');
        
    return { groupId, subGroupId };
  }

  lokiPostsListener(collectionName) {
    const { mode, post } = this.state;
    if (collectionName == 'posts' && mode != "draft" && post.id)
      this.setState({ post });
  }

  onEnterEditMode(post) {
    this.setState({ mode: "draft", post });
  }

  render() {
    const {
      onSelect,
      onClose,
      onPostSave,
      hideNavigation,
      onCardLoad,
      changeEditMode,
      isSafety,
      location,
      isMainCard
    } = this.props;
    const { mode, post, groupId, subGroupId, postCreationParams } = this.state;

    if (!post) return null;

    if (mode == "draft") {
      return (
        <NewPostPage
          location={location}
          groupId={postCreationParams?.groupId}
          subGroupId={postCreationParams?.subGroupId}
          getPostCreationParams={this.getPostCreationParams}
          key={`post_draft_${post.id || "new"}`}
          onCardLoad={onCardLoad}
          hideNavigation={hideNavigation}
          post={post}
          onCancel={() => {
            this.setState({ mode: null });
            if (isMainCard) changeEditMode(false);
            if (!post || !post.id) {
              onSelect && onSelect(null, true);
            }
          }}
          onDone={(newPost) => {
            this.setState({ mode: null, post: newPost });
            if (isMainCard) changeEditMode(false);
          }}
          onPostSave={onPostSave}
          isSafety={isSafety}
          onEnterEditMode={this.onEnterEditMode}
        />
      );
    } else
      return (
        <PostViewer
          onCardLoad={onCardLoad}
          onEnterEditMode={this.onEnterEditMode}
          {...this.props}
          post={post}
        />
      );
  }
}

export default Post = connectContext(ProjectContext.Consumer)(Post);

// const enhance = compose(
//   connectContext(ProjectContext.Consumer),
//   connect(state => ({}), { draftValidator })
// );

// export default Post = enhance(Post);

class PostViewer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.getRef = this.getRef.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onReassign = this.onReassign.bind(this);
    this.onExportPost = this.onExportPost.bind(this);
    this.onUpdateState = this.onUpdateState.bind(this);
    this.handleReassign = this.handleReassign.bind(this);
    this.createPostStory = this.createPostStory.bind(this);
    this.openImagesModal = this.openImagesModal.bind(this);
    this.closeImagesModal = this.closeImagesModal.bind(this);
    this.createPostButtons = this.createPostButtons.bind(this);
    this.handleUpdateState = this.handleUpdateState.bind(this);
    this.onSingleAction = this.onSingleAction.bind(this);
    this.getPermissions = this.getPermissions.bind(this);
    this.getImagesArray = this.getImagesArray.bind(this);

    this.handleCommentCreate = this.handleCommentCreate.bind(this);
    this.onCloseSingleAction = this.onCloseSingleAction.bind(this);
    this.onSelectableReassignChange =
      this.onSelectableReassignChange.bind(this);

    let images = this.getImagesArray(props);

    this.defaultFooterFlags = {
      commentMode: false,
      reassignMode: false,
      allButtonsMode: true,
      selectedReassign: null,
    };
    this.state = {
      images,
      allButtonsMode: true,
      ...this.defaultFooterFlags,
      pdfDisplay: null,
    };
    this.objectPropertiesPageRef = null;
  }

  UNSAFE_componentWillMount() {
    this.lokiPropertyInstances =
      lokiInstance.getCollection("propertyInstances");
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isValDiff(this.props, ["comments"])) {
      let oldCommentsCount = 0;
      let newCommentsCount = 0;
      prevProps.getNested(["comments"], {})
        .loopEach((id) => oldCommentsCount++);
      this.props.getNested(["comments"], {})
        .loopEach((id) => newCommentsCount++);

      if (this.state.shouldScrollToStoryComment == newCommentsCount)
        this.postStoryRef.scrollTop = this.postStoryRef.scrollHeight;
    }
  }

  handleDeletePost = async (post = this.props.post, projectId = this.props.selectedProjectId) => {
    const { startToast, onClose } = this.props;
    const shouldDelete = await new Promise(resolve => {
      startToast({
        overlay: true, 
        mandatory: true,
        title: postsMessages.delete.title[post.isIssue ? 'task' : 'record'], 
        message: postsMessages.delete.content[post.isIssue ? 'task' : 'record'], 
        actions: [
          { message: systemMessages.yes, onClick: () => resolve(true), color: 'success'},
          { message: systemMessages.no, onClick: () => resolve(false) }
        ]
      });
    });

    if (shouldDelete) {
      deletePost(post, projectId);
      onClose();
    }
  }

  setComponentData(props, nextProps) {
    const {
      onCardLoad,
      selectedProjectId,
      viewer,
      onEnterEditMode,
    } = this.props;
    let newStateChanges = {};

    const isPostDiff = !_.isEqual(props.post, nextProps.post);
    if (isPostDiff) {
      newStateChanges.images = this.getImagesArray(nextProps);
      newStateChanges.permissions = this.getPermissions(nextProps);
      newStateChanges.buttons = this.createPostButtons(
        nextProps,
        newStateChanges.permissions
      );

      newStateChanges.locationTitles = getLocationTitle(
        nextProps.getNested(["buildings"]),
        nextProps.getNested(["floors"]),
        nextProps.getNested(["units"]),
        nextProps.post.getNested(["location", "building", "id"]),
        nextProps.post.getNested(["location", "floor", "id"]),
        nextProps.post.getNested(["location", "unit", "id"]),
        null,
        nextProps.intl,
        nextProps.projects.getNested([selectedProjectId, "type"]) !=
        PROJECT_TYPE_COMPLEX_BUILDINGS
      );

      let headerOptions = {
        postState: nextProps.getNested(["post", "issueState"], "documentation"),
        editable: Boolean(
          newStateChanges.getNested(["permissions", "editPostPermission"])
        ),
        onDelete: newStateChanges.getNested([
          "permissions",
          "deletePostPermission",
        ])
          ? () => {
            this.handleDeletePost(nextProps.post, selectedProjectId);
          }
          : null,
        onPrint: () => this.onExportPost(nextProps.post),
        customIcons: [
          Boolean(!nextProps.post.isIssue && viewer.adminMode == 1) && {
            component: (
              <div
                style={{
                  fontSize: 11,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyItems: "center",
                  lineHeight: 0.73,
                }}
              >
                <span>Convert</span>
                <span>to</span>
                <span>task</span>
              </div>
            ),
            onClick: () =>
              onEnterEditMode({
                ...nextProps.post,
                isIssue: true,
                issueState: 300,
              }),
          },
        ],
      };
      if (onCardLoad) onCardLoad(headerOptions, null, null, null, null, null);
    }


    if (props.isValDiff(nextProps, ["post", "id"])) {
      newStateChanges = Object.assign(newStateChanges, this.defaultFooterFlags);
    }

    if (
      props.style != nextProps.style ||
      isPostDiff ||
      props.isValDiff(nextProps, ["comments",])
    )
      newStateChanges.story = this.createPostStory(nextProps);

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  createPostStory(nextProps) {
    let story = [];
    story.push({
      id: storyTypes.new,
      type: storyTypes.new,
      createdAt: 1,
      title: <Text>{issuesMessages.story.created}</Text>,
      subTitle: (
        <div style={{ display: "flex" }}>
          <DisplayName
            style={{
              ...theme.defaultFont,
              fontSize: 12,
              fontWeight: theme.bold,
            }}
            message={issuesMessages.story.by}
            messageValueProperty={"name"}
            userId={nextProps.getNested(["post", "owner", "id"])}
          />
          <Date style={{ paddingLeft: 5, paddingRight: 5 }} format={{ detailed: true }}>
            {nextProps.getNested(["post", "createdAt"])}
          </Date>
        </div>
      ),
      content: (
        <Text style={{ maxWidth: "40vw" }}>
          {nextProps.getNested(["post", "title"])}
        </Text>
      ),
      imageURIs: this.getImagesArray(nextProps),
    });

    const attachmentsArr = _.values(nextProps.post?.attachments).filter(att => !att?.isDeleted && att?.uri);
    if (attachmentsArr.length) {
      const groupedAtt = _.groupBy(attachmentsArr, att => {
        return att.owner?.id + moment(att.uploadTS).format('DD/MM/YYYY hh:mm');
      });

      const sortedGroupedAtts = _.values(groupedAtt).sort((a, b) => {
        return moment(a[0].uploadTS).valueOf() - moment(b[0].uploadTS).valueOf();
      });

      _.values(sortedGroupedAtts).forEach((atts) => {
        const { id, owner, uploadTS } = atts[0];
        story.push({
          id,
          createdAt: uploadTS,
          type: storyTypes.attachedFile,
          title: <Text>{issuesMessages.story.attachmentUploaded}</Text>,
          subTitle: (
            <div style={{ display: "flex" }}>
              <DisplayName
                style={{
                  ...theme.defaultFont,
                  fontSize: 12,
                  fontWeight: theme.bold,
                }}
                message={issuesMessages.story.by}
                messageValueProperty={"name"}
                userId={owner?.id}
              />
              <Date style={{ paddingLeft: 5, paddingRight: 5 }} format={{ detailed: true }}>
                {uploadTS}
              </Date>
            </div>
          ),
          content: (
            <>
            <StandardInput 
              isReadOnly
              type={propertyTypes.DRAWINGS_ARRAY}
              value={atts}
            />
            </>
          ),
        });
      });
    }

    let commentsArray = nextProps.comments || []
    commentsArray = commentsArray.sort((a, b) => a.createdAt - b.createdAt);
    commentsArray.loopEach((index, comment) => {
      let currStory = {
        id: comment.id,
        createdAt: comment.createdAt,
        type: storyTypes.statusUpdated,
        subTitle: (
          <div style={{ display: "flex" }}>
            <DisplayName
              style={{
                ...theme.defaultFont,
                fontSize: 12,
                fontWeight: theme.bold,
              }}
              message={issuesMessages.story.by}
              messageValueProperty={"name"}
              userId={comment.getNested(["owner", "id"])}
            />
            <Date
              style={{ paddingLeft: 5, paddingRight: 5 }}
              format={{ detailed: true }}
            >
              {comment.createdAt}
            </Date>
          </div>
        ),
      };
      if (comment.type == "status" && comment.owner) {
        if (comment.data.status == issueStates.ISSUE_STATE_RESOLVED)
          currStory.title = <Text>{issuesMessages.story.resolved}</Text>;
        else if (comment.data.status == issueStates.ISSUE_STATE_OPENED)
          currStory.title = <Text>{issuesMessages.story.reopend}</Text>;
        else if (comment.data.status == issueStates.ISSUE_STATE_CLOSED)
          currStory.title = <Text>{issuesMessages.story.closed}</Text>;
      } else if (
        (comment.type == "forward" || comment.type == "forwardIssue") &&
        comment.getNested(["data", "newAssignTo", "id"])
      )
        currStory.title = (
          <DisplayName
            messageValueProperty={"name"}
            userId={comment.data.newAssignTo.id}
            message={issuesMessages.story.reassigned}
          />
        );
      else if (comment.content || comment.images) {
        if (comment.images) {
          currStory.imageURIs = [];
          comment.images.loopEach((k, v) => {
            if (v && v.uri) currStory.imageURIs.push(v.uri);
          });
          if (currStory.imageURIs.length == 0) currStory.imageURIs = null;
        }
        currStory.type = comment.images
          ? storyTypes.commentWithImage
          : storyTypes.comment;
        currStory.title = currStory.imageURIs ? (
          <Text values={{ count: currStory.imageURIs.length }}>
            {issuesMessages.story.uploaded}
          </Text>
        ) : (
          <Text>{issuesMessages.story.comment}</Text>
        );
        currStory.content = (
          <Text data-qa="comment-text-content" style={{ maxWidth: "40vw" }}>{comment.content}</Text>
        );
      }

      story.push(currStory);
    });

    return story;
  }

  getPermissions(nextProps) {
    const { selectedProjectId, post, viewer } = nextProps;

    let editPostPermission = permissionsFunc.isPermitted(viewer, selectedProjectId, 'posts', 'edit', post, 'post');
    let deletePostPermission = viewer && viewer.adminMode == 1;//permissionsFunc.isPermitted(viewer, selectedProjectId, 'posts', 'delete', post, 'post');

    if (!post.isIssue)
      return ({ editPostPermission, deletePostPermission });

    return ({
      reOpenPermission: permissionsFunc.isPermitted(viewer, selectedProjectId, 'posts', 'reOpen', post, 'post'),
      reAssignPermission: permissionsFunc.isPermitted(viewer, selectedProjectId, 'posts', 'reAssign', post, 'post'),
      openOrClosePermission: permissionsFunc.isPermitted(viewer, selectedProjectId, 'posts', 'openOrClose', post, 'post'),
      resolvePermission: permissionsFunc.isPermitted(viewer, selectedProjectId, 'posts', 'resolve', post, 'post'),
      closePermission: permissionsFunc.isPermitted(viewer, selectedProjectId, 'posts', 'close', post, 'post'),
      editPostPermission,
      deletePostPermission,
    });
  }

  createPostButtons(nextProps, postPermissions) {
    const { post, hideButtons } = nextProps;

    let allActionButtons = {
      comment: {
        label: postsMessages.commentButton,
        func: () => this.onSingleAction("comment"),
        icon: actionComment,
        style: {},
      },
      close: {
        label: issuesMessages.closeButton,
        func: () => {
          this.onUpdateState(issueStates.ISSUE_STATE_CLOSED);
        },
        icon: actionClose,
        style: {},
      },
      reopen: {
        label: issuesMessages.reopenButton,
        func: () => {
          this.onUpdateState(issueStates.ISSUE_STATE_OPENED);
        },
        icon: actionReopen,
        style: {},
      },
      resolve: {
        label: issuesMessages.resolveButton,
        func: () => {
          this.onUpdateState(issueStates.ISSUE_STATE_RESOLVED);
        },
        icon: actionClose,
        style: {},
      },
      reassign: {
        label: issuesMessages.forwardButton,
        func: () => this.onSingleAction("reassign"),
        icon: actionReassign,
        style: {},
      },
    };
    let buttons = [];
    buttons.push(allActionButtons.comment);

    //let postPermissions = this.getPermissions(nextProps);
    if (!hideButtons && post.isIssue) {
      if (!post.issueState || post.issueState == issueStates.ISSUE_STATE_OPENED) {
        if (postPermissions.openOrClosePermission || postPermissions.closePermission) buttons.push(allActionButtons.close);
        else if (postPermissions.resolvePermission) buttons.push(allActionButtons.resolve);
        if (postPermissions.reAssignPermission) buttons.push(allActionButtons.reassign);
      }
      else if (post.issueState == issueStates.ISSUE_STATE_RESOLVED) {
        if (postPermissions.openOrClosePermission || postPermissions.closePermission)
          buttons.push(allActionButtons.close);
        if (postPermissions.openOrClosePermission)
          buttons.push(allActionButtons.reopen);
        if (postPermissions.reAssignPermission)
          buttons.push(allActionButtons.reassign);
      } else if (post.issueState == issueStates.ISSUE_STATE_CLOSED)
        if (postPermissions.reOpenPermission)
          buttons.push(allActionButtons.reopen);
    }

    return buttons;
  }

  getImagesArray(nextProps) {
    let images = [];
    if (nextProps.post) {
      images = Object.values(nextProps.post.images || {})
        .filter(image => !image.isDeleted);
    }
    if (images.length == 0) return null;
    return images.map((curr) => {
      if (curr && curr.uri) return curr.uri;
    });
  }

  openImagesModal(selectedStoryIndex, selectedImageIndex) {
    const { story } = this.state;
    let imagesArray = [];
    let initialIndex = 0;
    (story || []).forEach((currStory, storyIndex) => {
      if (!currStory.imageURIs) return;
      initialIndex +=
        storyIndex < selectedStoryIndex ? currStory.imageURIs.length : 0;
      currStory.imageURIs.forEach((currImage, imageIndex) => {
        imagesArray.push({ description: currStory.content, src: currImage });
        initialIndex +=
          storyIndex == selectedStoryIndex && imageIndex < selectedImageIndex
            ? 1
            : 0;
      });
    });

    this.setState({
      imagesModalObject: { images: imagesArray, selectedIndex: initialIndex },
    });
  }

  closeImagesModal() {
    this.setState({ imagesModalObject: null });
  }

  async handleCommentCreate(comment, parentId) {
    const {
      selectedProjectId,
      viewer,
    } = this.props;
    const { comments } = this.props;
    this.setState({
      commentMode: false,
      shouldScrollToStoryComment: (comments || []).length + 1,
    });
    let imageFile = null;
    let commentImageRef = null;
    if (comment.images) {
      let imageId = Object.keys(comment.images || {})[0];
      commentImageRef = comment.images[imageId];
      imageFile = commentImageRef.data;
      delete commentImageRef.data;
    }

    const response = await createLocalComment(
      viewer,
      selectedProjectId,
      parentId,
      comment,
      !imageFile
    );
    if (!response) return;
    let newComment = response.comment;

    if (response && imageFile) {
      commentImageRef.uri = await uploadImage(
        imageFile,
        selectedProjectId + "/" + newComment.id + "_" + commentImageRef.id,
        "comments"
      );
      delete commentImageRef.uploading;
      newComment.images = {};
      newComment.images[commentImageRef.id] = commentImageRef;
    }
    await uploadComment(viewer, selectedProjectId, parentId, newComment);
    this.setState({ allButtonsMode: true });
  }

  onSelectableReassignChange(selectedItem) {
    this.setState({ selectedReassign: selectedItem.user });
  }

  onReassign() {
    const { post, startToast, comments } = this.props;
    const { selectedReassign } = this.state;
    if (selectedReassign) {
      startToast({
        overlay: true,
        mandatory: true,
        title: issuesMessages.updateAssignee,
        message: issuesMessages.reassignAlertMessage,
        values: { name: selectedReassign.displayName },
        actions: [
          {
            message: systemMessages.yes,
            onClick: () => this.handleReassign(post, selectedReassign),
            color: "success",
          },
          { message: systemMessages.no },
        ],
      });
      this.onCloseSingleAction(null, {
        shouldScrollToStoryComment: (comments || []).length + 1,
      });
    }
  }

  onUpdateState(newState) {
    const { startToast } = this.props;

    let messages = {
      [issueStates.ISSUE_STATE_RESOLVED]: issuesMessages.resolveAlertMessage,
      [issueStates.ISSUE_STATE_CLOSED]: issuesMessages.closeAlertMessage,
      [issueStates.ISSUE_STATE_OPENED]: issuesMessages.reOpenAlertMessage,
    };
    startToast({
      overlay: true,
      mandatory: true,
      title: issuesMessages.updateState,
      message: messages[newState],
      actions: [
        {
          message: systemMessages.yes,
          onClick: () => this.handleUpdateState(newState),
          color: "success",
        },
        { message: systemMessages.no },
      ],
    });
  }

  handleReassign(post, assignTo) {
    const { viewer, selectedProjectId } = this.props;
    updateIssueAssignTo(viewer, post, selectedProjectId, assignTo);
  }

  isChecklistInstancePropertiesFulfilled(extraData, checklistInstance) {
    let ids = [];
    let ret = true;
    _.forIn(extraData, (prop) => {
      if (!prop.optional && !prop.readOnly) {
        let id = _.get(checklistInstance, ["extraData", prop.id, "instanceId"]);
        if (!id) ret = false;
        else ids.push({ id });
      }
    });
    let instances = this.lokiPropertyInstances.cementoFind({ $or: ids });
    instances.forEach((propInstance) => {
      let data = propInstance.data;
      if (_.isNil(data) || data == "") ret = false;
    });
    return ret;
  }

  handleUpdateState(newState) {
    const {
      post,
      startToast,
      viewer,
      selectedProjectId,
      comments,
    } = this.props;
    let { checklistInstances, checklistItems } = this.props;

    updateIssueState(viewer, post, selectedProjectId, newState);

    if (post.checklistItemInstance) {
      let checklistItemInstanceId = _.get(post, [
        "checklistItemInstance",
        "id",
      ]);

      let checklistItemInstance = _.get(checklistInstances, [
        checklistItemInstanceId,
      ]);
      let checklistItemId = _.get(checklistItemInstance, ["checklistItemId"]);
      let extraData = _.get(checklistItems, [checklistItemId, "extraData"]);
      if (extraData) {
        let isFulfilled = this.isChecklistInstancePropertiesFulfilled(
          extraData,
          checklistItemInstance
        );
        if (!isFulfilled) {
          startToast({
            title:
              checklistItemMessages.extraData.postMissingExtraDataAlertTitle,
            type: "error",
          });
          return;
        }
      }

      let { allOthersWithWantedState, myDefaultActionStatus } =
        areAllOtherConnectedPosts_withWantedStatus(
          post.checklistItemInstance.id,
          post.id,
          selectedProjectId,
          newState,
          viewer
        );

      let issueStateToChecklistStatusMap = {
        [issueStates.ISSUE_STATE_CLOSED]: myDefaultActionStatus,
        [issueStates.ISSUE_STATE_RESOLVED]: cliStatus.CLI_STATUS_RESOLVED,
        [issueStates.ISSUE_STATE_OPENED]: cliStatus.CLI_STATUS_REJECTED,
      };
      let messages = {
        [issueStates.ISSUE_STATE_RESOLVED]:
          issuesMessages.alsoResolveItemAlertMessage,
        [issueStates.ISSUE_STATE_CLOSED]:
          issuesMessages.alsoConfirmItemAlertMessage,
        [issueStates.ISSUE_STATE_OPENED]: issuesMessages.alsoRejectItemMessage,
      };

      if (
        allOthersWithWantedState &&
        issueStateToChecklistStatusMap[newState]
      ) {
        const bHideAlert = this.props.getNested([
          "configurations",
          "alerts",
          "hideAlertOfConnectedPostsStateUpdate",
        ]);
        const inChecklistItemInstance = { ...checklistInstances[checklistItemInstanceId], status: issueStateToChecklistStatusMap[newState] };
        if (bHideAlert)
          upsertChecklistItemInstance({
            projectId: selectedProjectId,
            inChecklistItemInstance,
          });
        else
          startToast({
            overlay: true,
            mandatory: true,
            title: issuesMessages.connectedChecklist,
            message: messages[newState],
            actions: [
              {
                message: systemMessages.yes,
                color: "success",
                onClick: () =>
                  upsertChecklistItemInstance({
                    projectId: selectedProjectId,
                    inChecklistItemInstance,
                  })
              },
              { message: systemMessages.no },
            ],
          });
      }
    }
    this.props.setCardHeaderParams({ postState: newState });
    this.setState({ shouldScrollToStoryComment: (comments || []).length + 1 });
  }

  onCloseSingleAction(event, additionStateChanges) {
    this.setState({ ...this.defaultFooterFlags, ...additionStateChanges });
  }

  onSingleAction(type) {
    if (type == "comment")
      this.setState({
        commentMode: true,
        reassignMode: false,
        allButtonsMode: false,
        selectedReassign: null,
      });
    else if (type == "reassign")
      this.setState({
        commentMode: false,
        reassignMode: true,
        allButtonsMode: false,
        selectedReassign: null,
      });
  }

  async onExportPost() {
    const { post, exportPostsPDF, viewer, selectedProjectId } = this.props;
    let pdf = await exportPostsPDF([post], selectedProjectId, viewer);
    let pdfDisplay = null;
    if (pdf && pdf.uri && pdf.uri.startsWith("http")) pdfDisplay = pdf.uri;

    this.setState({ pdfDisplay });
  }
  hidePdfDisplay = () => this.setState({ pdfDisplay: null });

  onClick() {
    const { onSelect, post } = this.props;
    if (onSelect && post) onSelect(post);
  }

  getRef(node) {
    this.postStoryRef = node;
  }

  render() {
    const {
      post,
      classes,
      selectedProjectId,
      requiredActions,
      subCategoriesMap,
      projectMembers,
      viewer,
      propertiesTypes,
    } = this.props;

    const {
      images,
      commentMode,
      story,
      buttons,
      reassignMode,
      imagesModalObject,
      allButtonsMode,
      pdfDisplay,
      locationTitles,
    } = this.state;

    if (!post) return null;

    let assignToOtherMembers = null;

    if (reassignMode) {
      assignToOtherMembers = Object.assign({}, projectMembers);
      let currentAssignee = post.assignTo ? post.assignTo.id : null;
      delete assignToOtherMembers[currentAssignee];
    }

    const postsStageObj = { title: post.stageTitle };
    const postsChecklistObj = { title: post.checklistTitle };

    const isSafetyPost = _.get(post, ["trade", "id"]) === SAFETY_TRADE_ID;

    return (
      <>
        <PostViewButtons
          post={post}
          buttons={buttons}
          commentMode={commentMode}
          handleCommentCreate={this.handleCommentCreate}
          onCloseSingleAction={this.onCloseSingleAction}
          allButtonsMode={allButtonsMode}
          reassignMode={reassignMode}
          onSelectableReassignChange={this.onSelectableReassignChange}
          onReassign={this.onReassign}
          classes={classes}
          assignToOtherMembers={assignToOtherMembers}
        />
        <MenuScrollbar
          isSmooth={true}
          style={{ flex: 1 }}
          getScrollRef={this.getRef}
        >
          <div style={{ flex: 1, flexDirection: "column", marginBottom: theme.rowHeight }}>
            <PostOldContent
              post={post}
              classes={classes}
              images={images}
              isSafetyPost={isSafetyPost}
              pdfDisplay={pdfDisplay}
              postsStageObj={postsStageObj}
              postsChecklistObj={postsChecklistObj}
              subCategoriesMap={subCategoriesMap}
              requiredActions={requiredActions}
              locationTitles={locationTitles}
            />
            <PostTimeLine
              story={story}
              viewer={viewer}
              selectedProjectId={selectedProjectId}
              post={post}
              openImagesModal={this.openImagesModal}
            />
            <PostProperties
              postId={post.id}
              post={post}
              key={`PostsProperties${post.id}`}
              mode={"card"}
              subjectName={POST_INFO_SUBJECT_NAME}
              isCreateMode={false}
              createObjectMode={false}
              useSections
              useMenusScrollsBar={false}
              onRef={(ref) => this.objectPropertiesPageRef = ref}
              inputsContainerStyle={{ marginBottom: theme.padding }}
            />
          </div>
        </MenuScrollbar>

        {Boolean(imagesModalObject) && (
          <ImageCarousel
            onClose={this.closeImagesModal}
            initialSlide={
              imagesModalObject ? imagesModalObject.selectedIndex : 0
            }
            items={imagesModalObject.images}
          />
        )}

      </>
    );
  }
}

let styles = {
  postOldContentLabel: {
    color: theme.brandNeutral,
    fontSize: 'inherit',
    marginBottom: 5
  },
  postOldContentFieldContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minWidth: '50%',
    marginBottom: theme.verticalMargin
  },
  postOldContentValue: {
    color: theme.inverseBackgroundColor,
    fontSize: theme.inputFontSize,
    lineHeight: `${theme.inputFontSize}px`,
  },
  p: {
    overflow: "hidden",
    fontSize: "0.8vw",
    textOverflow: "ellipsis",
    margin: "0px",
    whiteSpace: "wrap",
  },
  outlinedButtons: {
    fontSize: "10px",
    borderRadius: 0,
    color: theme.brandNeutral,
    backgroundColor: "transparent",
  },
  cardSectionsStyles: {
    padding: theme.paddingSize + "px 30px",
    boxShadow: "rgba(0, 0, 0, 0.08) 0px 1px 20px 0px",
    marginBottom: theme.paddingSize,
    display: "flex",
    flexDirection: "column",
  },
};

const withPostsHook = PostViewerClass => props => {
  const propsPost = props.post;
  const { currViewPosts, currViewComments } = usePosts({
    ignoreContentType: true,
    inIds: [propsPost.id],
  }, {
    includeComments: true,
  });

  return <PostViewerClass
    {...props}
    post={currViewPosts[0] || propsPost}
    comments={currViewComments}
  />;
};

const enhance = compose(
  withChecklistItemInstances,
  injectIntl,
  withStyles(styles),
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      rtl: state.app.rtl,
      trades: state.trades.map,
      requiredActions: state.quasiStatics.requiredActionsMap,
      subCategoriesMap: state.quasiStatics.subCategoriesMap,
    }),
    {
      onDraftModeChange,
      exportPostsPDF,
      startToast,
    }
  ),
  withPostsHook,
);

PostViewer = enhance(PostViewer);

class NewPostPage extends React.Component {
  constructor(props) {
    super(props);
    this.getRef = this.getRef.bind(this);
    this.getSortedTrades = this.getSortedTrades.bind(this);
    this.onPostChange = this.onPostChange.bind(this);
    this.onRequiredActionSelection = this.onRequiredActionSelection.bind(this);
    this.checkIsDescriptionMandatory = this.checkIsDescriptionMandatory.bind(this);
    this.savePost = this.savePost.bind(this);
    this.setDefaultTagSelected = this.setDefaultTagSelected.bind(this);
    this.fieldsRef = {};

    this.objectPropertiesPageRef = null;
    this.state = {
      mandatoryData: {},
      post: {},
      sortedTrades: [],
      sortedSubCategories: [],
      safetyMode: false,
      checklistInstanceCallback: null,
    };
  }

  setDraftMode(bool) {
    const { onDraftModeChange } = this.props;
    if (this.lastDraftMode === bool) return;
    this.lastDraftMode = bool;
    onDraftModeChange(bool);
  }

  componentDidMount() {
    const { onCardLoad, onCancel, draftValidator, post, onDraftModeChange } = this.props;
    let contentType =
      post && post.isIssue ? issuesMessages.task : postsMessages.documentation;
    let creationMode = Boolean(post && post.mode == "draft");
    onCardLoad(
      {
        editable: true,
        onCancel:()=>{
          onCancel?.();
          this.setDraftMode(false);
        },
        onSave: () => this.savePost(),
      },
      {},
      true,
      creationMode,
      contentType,
      true
    );
  }

  componentWillUnmount() {
    this.setDraftMode(false);
  }

  setDefaultTagSelected(inPost) {
    const { post } = this.state;
    const { projectMembers, selectedProjectId, viewer } = this.props;

    var newPost = Object.assign({}, inPost || post);
    newPost = setDefaultTagSelected(
      newPost,
      projectMembers,
      viewer,
      selectedProjectId
    );

    this.setState({ post: newPost });

    return newPost;
  }

  async savePost(reopenNewPostWizard) {
    const {
      viewer,
      selectedProjectId,
      onEnterEditMode,
      onPostSave,
      startToast,
      getPostCreationParams,
    } = this.props;
    const {
      onClose,
      onDone,
      checklistItemInstance,
      type,
      reportId,
      isCloseOnSave = true,
      startLoading,
      hideLoading,
    } = this.props;

    if (this.didPress) return false;
    this.didPress = true;

    const { checklistInstanceCallback, mandatoryData, isNewPost } = this.state;

    if (this.fieldsRef) {
      let errors = {};
      this.fieldsRef.loopEach((propId, ref) => {
        let err = ref?.component?.checkErrors();
        if (err) errors[propId] = err.join(", ");
      });
      if (Object.keys(errors).length) {
        startToast({
          title: systemMessages.invalidDetailsDescription,
          values: { errors: Object.values(errors).join("\n") },
          type: "error",
        });
        this.didPress = false;
        return false;
      }
    }
    let post = Object.assign({}, this.state.post);
    if (post.titlePrefix) {
      post.title = `${post.titlePrefix} ${post.title}`;
      _.unset(post, ["titlePrefix"]);
    }

    let notValidKey = isPostInValid(post, mandatoryData);
    if (notValidKey) {
      startToast({ title: newPostMessages.mandatoryFields[notValidKey] });
      this.didPress = false;
      return false;
    }
    
    const operationId = uuidv4();
    startLoading({ title: systemMessages.savingMessage, overlay: true, operationId })
    if (
      typeof (this.objectPropertiesPageRef || {}).handleSave === "function"
    ) {
      const isUpdateSucceeded =
        await this.objectPropertiesPageRef.handleSave(undefined, true);
      if (!isUpdateSucceeded) {
        hideLoading(operationId);
        this.didPress = false;
        return false
      }
    }

    this.setState({ uploading: true });

    try {
      post = this.setDefaultTagSelected(post);

      if (checklistItemInstance)
        post.checklistItemInstance = checklistItemInstance;

      if (post.isIssue) {
        if (isNewPost) {
          post.issueState = issueStates.ISSUE_STATE_OPENED;
        }
      }

      if (post.images) {
        let images = {};
        const imagesArray = _.values(post.images);
        imagesArray.forEach((image) => {
          const shouldUpload = !Boolean(image.uri?.startsWith?.('http'))
          const id = uuidv4();
          images[id] = {
            id,
            uploading: shouldUpload,
            ...image,
          };
        });
        post.images = images
      }
     
      if (post.attachments) {
        post.attachments = _.values(post.attachments).map((attachment) => {
          const id = uuidv4();
          return { id, ...attachment };
        });
      }

      // Add the post locally only
      post.mode = null;
      post = (await createLocalPost(viewer, selectedProjectId, post)).post;
      post = { ...post };
      const calculatedFieldsCache = {
        checklistTitle: post.checklistTitle,
        stageTitle: post.stageTitle,
      };
      if (!reopenNewPostWizard) {
        if (onClose && isCloseOnSave) onClose();
        if (onPostSave) onPostSave(post);
      }

      if (reportId) post.reportId = reportId;

      if (checklistInstanceCallback) {
        let instanceId = checklistInstanceCallback(post);
        post.checklistItemInstance = { id: instanceId };
      }

      let callBack = () =>
        startToast({
          title: post.isIssue
            ? postsMessages.issueSaved
            : postsMessages.postSaved,
          type: "success",
        });

      const uploadPostAction = isNewPost ? createPost : updatePost;
      const retPromise = uploadPostAction(viewer, selectedProjectId, post, false, callBack);

      var ret = await retPromise;
      if (!ret) {
        hideLoading(operationId);
        return false;
      }
      post = {
        ...ret.post,
        ...calculatedFieldsCache,
      };

      if (onDone && !reopenNewPostWizard) onDone(post);

      if (reopenNewPostWizard) {
        const { assignTo, location, dueDate, trade, isIssue } = post;
        var newPost = Object.assign(
          {},
          { isIssue, assignTo, location, dueDate, trade, mode: "draft", id: getNewPostId(selectedProjectId) }
        );
        if (isIssue) newPost.issueState = issueStates.ISSUE_STATE_OPENED;
        const postCreationParams = getPostCreationParams(this.props);
        this.setState({ post: newPost, uploading: false, didChange: false, ...postCreationParams });
        if (_.isFunction(onEnterEditMode)) {
          onEnterEditMode(newPost);
        }
      } else {
        this.setDraftMode(false);
      }

      return post;
    } finally {
      hideLoading(operationId);
      this.didPress = false;
    }
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { sortedTrades, sortedSubCategories, post, isSafety, isNewPost } =
      this.state;
    let newStateChanges = {};

    let nextPost = post;
    if (
      props.isValDiff(nextProps, ["isSafety"]) ||
      props.isValDiff(nextProps, ["navigationParams", "contentType"])
    )
      newStateChanges.safetyMode = Boolean(
        nextProps.getNested(["navigationParams", "contentType"]) === "safety" ||
        nextProps.isSafety
      );

    const nextSefetyMode = newStateChanges.safetyMode || isSafety;
    if (props.isValDiff(nextProps, ["post"])) {
      nextPost = { ...(nextProps.post || {}) };

      if (!nextPost.id) {
        newStateChanges.isNewPost = true;
        nextPost.id =
          _.get(post, "id") ||
          getNewPostId(nextProps.selectedProjectId);
      } else if (isNewPost) newStateChanges.isNewPost = false;

      if (nextPost.trade && nextPost.trade.id)
        nextPost = nextPost.setNested(
          ["trade", "id"],
          nextPost.trade.id.toString()
        );
      else if (nextSefetyMode) nextPost.trade = { id: SAFETY_TRADE_ID };

      if (nextSefetyMode && _.isNil(nextPost.severity))
        nextPost.severity = POST_DEFAULT_SEVERITY;

      const checklistInstanceCallback = nextPost.checklistInstanceCallback;
      const mandatoryData = nextPost.mandatoryData || {};
      delete nextPost.mandatoryData;
      delete nextPost.checklistInstanceCallback;

      newStateChanges = {
        ...newStateChanges,
        checklistInstanceCallback,
        mandatoryData,
        post: nextPost,
      };
    }

    if (
      sortedTrades.length == 0 ||
      (props.trades != nextProps.trades && nextProps.trades.size > 0)
    )
      newStateChanges.sortedTrades = this.getSortedTrades(nextProps.trades);

    let postTrade = nextPost.getNested(["trade", "id"]);
    if (
      sortedSubCategories.length == 0 ||
      (props.subCategoriesMap.get(postTrade) !=
        nextProps.subCategoriesMap.get(postTrade) &&
        nextProps.subCategoriesMap.get(postTrade).size > 0)
    )
      this.setSortedSubCategories(postTrade);

    if (props.projectMembers != nextProps.projectMembers)
      newStateChanges.assignToMembers = Object.assign(
        {},
        nextProps.projectMembers || {}
      );

    this.setState({ ...newStateChanges });
  }

  getRef(node) {
    this.postStoryRef = node;
  }

  setSortedSubCategories(postTrade) {
    if (postTrade) {
      let { subCategoriesMap } = this.props;
      let sortedSubCategories = this.getSortedTrades(
        subCategoriesMap.get(postTrade) || {}
      );
      this.setState({ sortedSubCategories });
    }
  }

  getSortedTrades(trades) {
    let sortedTrades = [];
    trades.loopEach((i, t) => {
      if (t.getNested(["getTitle"]) && t.getNested(["getTitle"]))
        sortedTrades.push(t);
    });
    return sortedTrades.sort((a, b) =>
      a.getNested(["getTitle"]).localeCompare(b.getNested(["getTitle"]))
    );
  }

  onPostChange(path, val) {
    if (!path) return;
    
    if (val?.length && (path == 'images' || path == 'attachments')) {
      val = val.filter((item) => !item.isDeleted);
    }

    let post = this.state.post.setNested(
      Array.isArray(path) ? path : [path],
      val
    );
    
    this.setDraftMode(true);
    this.setState({ post });
  }

  onRequiredActionSelection(val) {
    const { requiredActions } = this.props;
    const { post } = this.state;
    let change = null;

    if (val) {
      const id = _.keys(val)?.[0]?.toString();
      const customText = (String(id) == '-1')
        ? post?.requiredAction?.customText
        : requiredActions.getNested([id, "getTitle"]);
      change = { id, customText };
    }

    
    this.onPostChange("requiredAction", change);
  }

  checkIsDescriptionMandatory(){
    const { post } = this.state;
  
    const images = Object.values(post.images || {});
    const attachments = Object.values(post.attachments || {});
    
    const imagesAndAttachments = [...images, ...attachments];
  
    const isMandatory = !imagesAndAttachments.some(item => item && !item.isDeleted);
    
    return isMandatory;
  }

  render() {
    const { classes, rtl, navigationParams, requiredActions, groupId, subGroupId, location } = this.props;
    const { sortedTrades, sortedSubCategories, post, safetyMode = false, assignToMembers, showObjectPropertiesPage } = this.state;
    let headerSectionStyle = { display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px 0px', color: theme.brandPrimary, fontFamily: "Assistant - Semi Bold", fontSize: 16, fontWeight: theme.strongBold, [rtl ? 'marginLeft' : 'marginRight']: 3 };

    let currentAssignee = _.get(post, ['assignTo'], null);

    const isSafetyIssue = post.isIssue && (_.get(post, ['trade', 'id']) === SAFETY_TRADE_ID || safetyMode);
    const newPostId = _.get(post, 'id');

    let locationId = (
      post.getNested(['location', 'unit', 'id']) ||
      post.getNested(['location', 'floor', 'id']) ||
      post.getNested(['location', 'building', 'id']) ||
      navigationParams.getNested(['unitId']) ||
      navigationParams.getNested(['floorId']) ||
      navigationParams.getNested(['buildingId'])
    );

    if (locationId == "_")
      locationId = null;

    const isDescriptionMandatory = this.checkIsDescriptionMandatory()

    return (
      <>
        <MenuScrollbar
          isSmooth={true}
          style={{ flex: 1 }}
          getScrollRef={this.getRef}
        >
          <div
            className={classes.cardSectionsStyles}
            style={{
              transition: "all 150ms ease 0s",
              backgroundColor: theme.backgroundColorBright,
            }}
          >
            <Text style={headerSectionStyle}>
              {newPostMessages.generalInfo}
            </Text>

            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              {Boolean(!safetyMode) && (
                <InputField
                  key={"trade"}
                  name={issuesMessages.trade}
                  isMandatory={true}
                  type={"SelectionList"}
                  businessType={"trades"}
                  style={{ padding: "10px 0px" }}
                  titleStyle={{
                    fontWeight: theme.strongBold,
                    color: theme.inverseBackgroundColor
                  }}
                  placeholder={newPostMessages.title_3}
                  onChange={(val) => {
                    let trade = _.isObject(val) ? Object.keys(val)[0].toString() : null;
                    this.onPostChange(["trade", "id"], trade);
                    this.setSortedSubCategories(trade);
                  }}
                  values={sortedTrades
                    .map((trade) => ({
                      id: trade.id,
                      title: trade.getCementoTitle(false),
                    }))
                    .filter((trade) => !_.isEmpty(trade.title))}
                  defaultValue={
                    post.getNested(["trade", "id"])
                      ? { [post.trade.id]: post.trade.id }
                      : null
                  }
                  onRef={(e, c) => {
                    this.fieldsRef["trade"] = { element: e, component: c };
                  }}
                  prop={{
                    title: issuesMessages.trade,
                    type: "SelectionList",
                    mandatory: true
                  }}
                />
              )}

              {Boolean(sortedSubCategories.length > 0) && (
                <InputField
                  key={"subCategory"}
                  name={newPostMessages.subCategory}
                  type={"SelectionList"}
                  businessType={"subCategory"}
                  style={{ padding: "10px 0px" }}
                  placeholder={newPostMessages.title_3}
                  onChange={(val) =>
                    this.onPostChange(
                      ["subCategory", "id"],
                      Object.keys(val)[0].toString()
                    )
                  }
                  values={sortedSubCategories.map((t) => ({
                    id: t.id,
                    title: t.getCementoTitle(),
                  }))}
                  defaultValue={
                    post.getNested(["subCategory", "id"])
                      ? { [post.subCategory.id]: post.subCategory.id }
                      : null
                  }
                  onRef={(e, c) => {
                    this.fieldsRef["subCategory"] = {
                      element: e,
                      component: c,
                    };
                  }}
                />
              )}

              <InputField
                type={"Location"}
                style={{ padding: "10px 0px" }}
                key={"location"}
                rtl={rtl}
                mode={"card"}
                name={issuesMessages.location}
                placeholder={newPostMessages.title_2}
                defaultValue={locationId ? { [locationId]: locationId } : null}
                onChange={(val) => {
                  const firstId = _.head(_.keys(val));
                  const locationFullDetails = getFullLocationDetailsByIdNoProps(firstId);
                  const locationIds = locationFullDetails.locationIds;
                  if (locationFullDetails.locationFound) {
                    let newLocation = {};
                    if (locationIds.buildingId)
                      newLocation = newLocation.setNested(
                        ["building", "id"],
                        locationIds.buildingId
                      );
                    if (locationIds.floorId)
                      newLocation = newLocation.setNested(
                        ["floor", "id"],
                        locationIds.floorId
                      );
                    if (locationIds.unitId)
                      newLocation = newLocation.setNested(
                        ["unit", "id"],
                        locationIds.unitId
                      );
                    this.onPostChange("location", newLocation);
                  }
                }}
                onRef={(e, c) => {
                  this.fieldsRef["Location"] = { element: e, component: c };
                }}
              />
              {Boolean(post.isIssue) && (
                <InputField
                  key={"assign"}
                  style={{ padding: "10px 0px" }}
                  prop={{
                    mandatory: true,
                    title: issuesMessages.assignTo,
                    type: null,
                  }}
                  value={currentAssignee}
                  onRef={(e, c) => {
                    this.fieldsRef["assign"] = { element: e, component: c };
                  }}
                  name={issuesMessages.assignTo}
                >
                  <SelectableUsers
                    showLine={true}
                    defaultValue={currentAssignee}
                    placeholder={newPostMessages.title_4}
                    onChange={(assignTo) =>
                      this.onPostChange("assignTo", assignTo)
                    }
                    users={assignToMembers}
                  />
                </InputField>
              )}
              <InputField
                type={"String"}
                style={{ padding: "10px 0px" }}
                key={"title"}
                prop={{
                  title: newPostMessages.description,
                  type: "String",
                  mandatory: Boolean(isDescriptionMandatory),
                }}
                rtl={rtl}
                mode={"card"}
                defaultValue={post.title}
                name={newPostMessages.description}
                placeholder={newPostMessages.title_1}
                onChange={(val) => this.onPostChange("title", val)}
                onRef={(e, c) => {
                  this.fieldsRef["title"] = { element: e, component: c };
                }}
                multiline
              />
              <InputField
                type={"DrawingsArray"}
                prop={{
                  title: newPostMessages.images,
                  type: "DrawingsArray",
                  mandatory: !post.title,
                }}
                settings={{ accept: ".png,.jpeg,.jpg" }}
                mode={"card"}
                key={"images"}
                style={{ padding: "10px 0px" }}
                withResize={false}
                name={newPostMessages.images}
                defaultValue={post.images ? Object.values(post.images) : null}
                onChange={(images) => {
                  this.onPostChange("images", images);
                }}
                onRef={(e, c) => {
                  if (e?.id) this.fieldsRef[e.id] = { element: e, component: c };
                }}
              />

              <InputField
                type={"DrawingsArray"}
                key={"attachments"}
                mode={"card"}
                accept='.pdf'
                style={{ padding: "10px 0px" }}
                prop={{
                  title: newPostMessages.attachments,
                  type: "DrawingsArray",
                }}
                withResize={false}
                name={newPostMessages.attachments}
                defaultValue={
                  post.attachments ? Object.values(post.attachments) : null
                }
                onChange={(attachments) => {
                  this.onPostChange("attachments", attachments);
                }}
                onRef={(e, c) => {
                  this.fieldsRef["attachments"] = { element: e, component: c };
                }}
              />

              {Boolean(post.isIssue) && (
                <InputField
                  key={"dueDate"}
                  name={issuesMessages.dueDate}
                  type={"Date"}
                  style={{ padding: "10px 0px" }}
                  defaultValue={post.dueDate}
                  onChange={(dueDate) => this.onPostChange("dueDate", dueDate)}
                  onRef={(e, c) => {
                    this.fieldsRef["dueDate"] = { element: e, component: c };
                  }}
                />
              )}

              {Boolean(isSafetyIssue) && (
                <InputField
                  key={"severity"}
                  style={{ padding: "10px 0px" }}
                  prop={{
                    mandatory: true,
                    title: newPostMessages.severity,
                    type: null,
                  }}
                  onRef={(e, c) => {
                    this.fieldsRef["severity"] = { element: e, component: c };
                  }}
                  value={post.getNested(["severity"])}
                  name={newPostMessages.severity}
                >
                  <div
                    style={{
                      display: "flex",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <div style={{ display: "flex", width: 60, height: 20 }}>
                      {severityColors.map((curr) => (
                        <Text
                          key={curr.val}
                          style={{
                            ...theme.defaultFont,
                            fontWeight: theme.bold,
                            flex: 1,
                            textAlign: "center",
                            alignSelf: "center",
                            backgroundColor: curr.color,
                            opacity: post.severity == curr.val ? 1 : 0.4,
                            border:
                              post.severity == curr.val
                                ? "1px solid " + theme.brandNeutralDark
                                : "none",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            this.onPostChange(["severity"], curr.val)
                          }
                        >
                          {curr.val}
                        </Text>
                      ))}
                    </div>
                    <Text
                      style={{
                        ...theme.defaultFont,
                        fontWeight: theme.bold,
                        margin: "0px 5px",
                      }}
                    >
                      {safetyMessages.severity.title[post.severity]}
                    </Text>
                  </div>
                </InputField>
              )}
            </div>
          </div>
          {Boolean(isSafetyIssue) && (
            <div
              className={classes.cardSectionsStyles}
              style={{
                transition: "all 150ms ease 0s",
                backgroundColor: theme.backgroundColorBright,
              }}
            >
              <Text style={headerSectionStyle}>{newPostMessages.followUp}</Text>
              <InputField
                key={"requiredAction"}
                name={newPostMessages.requiredAction}
                type={"SelectionList"}
                businessType={"requiredAction"}
                style={{ padding: "10px 0px" }}
                placeholder={newPostMessages.requiredActionPlaceholder}
                onChange={this.onRequiredActionSelection}
                defaultValue={
                  post.getNested(["requiredAction", "id"])
                    ? { [post.requiredAction.id]: post.requiredAction.id }
                    : null
                }
                onRef={(e, c) => {
                  this.fieldsRef["requiredAction"] = {
                    element: e,
                    component: c,
                  };
                }}
                values={[
                  ...requiredActions
                    .valueSeq()
                    .map((t) => ({ id: t.id, title: t.getCementoTitle() })),
                  Boolean(post.getNested(["requiredAction", "customText"])) && {
                    id: "-1",
                    title: post.getNested(["requiredAction", "customText"]),
                  },
                ]}
                isCreatable={true}
                onCreateOption={(customText) =>
                  this.onPostChange("requiredAction", { customText, id: "-1" })
                }
                createLabel={newPostMessages.createRequiredAction}
                isRemovable={false}
              />

              <InputField
                key={"fineAmount"}
                name={newPostMessages.fine}
                placeholder={newPostMessages.fineAmount}
                type={"Number"}
                style={{ padding: "10px 0px" }}
                defaultValue={post.getNested(["fine", "amount"])}
                onChange={(amount) =>
                  this.onPostChange(["fine", "amount"], amount)
                }
                onRef={(e, c) => {
                  this.fieldsRef["fine"] = { element: e, component: c };
                }}
              />
              {Boolean(post.getNested(["fine", "amount"], 0) > 0) && (
                <>
                  <InputField
                    key={"fineReceiver"}
                    style={{ padding: "10px 0px" }}
                    prop={{
                      title: newPostMessages.fineReceiver,
                      type: null,
                    }}
                    value={
                      post.getNested(["fine", "fineReciver"])
                        ? true
                        : null
                    }
                    onRef={(e, c) => {
                      this.fieldsRef["fineReceiver"] = {
                        element: e,
                        component: c,
                      };
                    }}
                    name={newPostMessages.fineReceiver}
                  >
                    <SelectableUsers
                      showLine={true}
                      defaultValue={post.getNested(["fine", "fineReciver"])}
                      placeholder={newPostMessages.fineReceiver}
                      onChange={(receiver) =>
                        this.onPostChange(["fine", "fineReciver"], receiver)
                      }
                      users={assignToMembers}
                    />
                  </InputField>
                  <InputField
                    type={"String"}
                    style={{ padding: "10px 0px" }}
                    key={"fineDescription"}
                    prop={{
                      title: newPostMessages.fineDescription,
                      type: "String",
                    }}
                    rtl={rtl}
                    defaultValue={post.getNested(["fine", "description"])}
                    name={newPostMessages.fineDescription}
                    placeholder={newPostMessages.fineDescriptionPlaceholder}
                    onChange={(val) =>
                      this.onPostChange(["fine", "description"], val)
                    }
                    onRef={(e, c) => {
                      this.fieldsRef["fineDescription"] = {
                        element: e,
                        component: c,
                      };
                    }}
                  />
                </>
              )}
            </div>
          )}
          <PostProperties
            key={`PostsProperties_${newPostId}`}
            initialGroupId={groupId}
            initialSubGroupId={subGroupId}
            isCreateMode={_.isEmpty(groupId)}
            mode={"card"}
            postId={newPostId}
            post={post}
            subjectName={POST_INFO_SUBJECT_NAME}
            createObjectMode
            useSections
            useMenusScrollsBar={false}
            onRef={(ref) => (this.objectPropertiesPageRef = ref)}
            inputsContainerStyle={{ marginBottom: 150 }}
            location={location}
          />
          <PostSaveButtons post={post} savePost={this.savePost} />
        </MenuScrollbar>
      </>
    );
  }
}

NewPostPage = withStyles(styles)(NewPostPage);
const NewPostPageEnhance = compose(
  injectIntl,
  withRouterHOC,
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      rtl: state.app.rtl,
      trades: state.trades.map,
      requiredActions: state.quasiStatics.requiredActionsMap,
      subCategoriesMap: state.quasiStatics.subCategoriesMap,
    }),
    {
      onDraftModeChange,
      draftValidator,
      startToast,
      startLoading,
      hideLoading,
    }
  )
);
NewPostPage = NewPostPageEnhance(NewPostPage);

const PostViewButtons = (props) => {
  const {
    post,
    buttons,
    commentMode,
    handleCommentCreate,
    allButtonsMode,
    onCloseSingleAction,
    reassignMode,
    onSelectableReassignChange,
    onReassign,
    classes,
    rtl,
    assignToOtherMembers
  } = props;

  return (
    <>
      {Boolean(post && buttons) && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minHeight: theme.headerHeightSecondary,
            borderBottom: theme.borderLineNeutralLight + "40",
          }}
        >
          {Boolean(commentMode) && (
            <div style={{
              alignItems: "center",
              display: "flex",
              flex: 1,
              [rtl ? "paddingRight" : "paddingLeft"]: 10
            }}>
              <Comment
                style={{ backgroundColor: theme.backgroundColorBright, paddingRight: '25px' }}
                parentId={post.id}
                onCreate={handleCommentCreate}
                newCommentMode={true}
              />
              <CloseRounded
                style={{
                  display: "flex",
                  alignSelf: "center",
                  justifyContent: "center",
                  fontSize: 16,
                  lineHeight: "16px",
                  cursor: "pointer",
                }}
                onClick={onCloseSingleAction}
              />
            </div>
          )}
          {Boolean(reassignMode) && (
            <GridContainer spacing={8} alignItems={"center"}>
              <GridItem xs={10}>
                <SelectableUsers
                  onChange={onSelectableReassignChange}
                  users={assignToOtherMembers}
                  autoFocus={true}
                />
              </GridItem>
              <GridItem xs={1}>
                <CheckRounded
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    justifyContent: "center",
                    fontSize: 16,
                    lineHeight: "16px",
                    cursor: "pointer",
                  }}
                  onClick={onReassign}
                />
              </GridItem>
              <GridItem xs={1}>
                <CloseRounded
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    justifyContent: "center",
                    fontSize: 16,
                    lineHeight: "16px",
                    cursor: "pointer",
                  }}
                  onClick={onCloseSingleAction}
                />
              </GridItem>
            </GridContainer>
          )}
          {Boolean(allButtonsMode) && (
            <div
              id={`post_actions_buttons_container`}
              style={{
                display: 'flex',
                flex: 1,
                padding: `0 ${theme.paddingSize}px`,
              }}
            >
              {Boolean(buttons) &&
                buttons.map(
                  (currButton) => (
                    <div data-qa={currButton.label.id} key={currButton.label.id} style={{ padding: '0 5px' }} >
                      <Button
                        key={currButton.label.id}
                        fullWidth={false}
                        onClick={currButton.func ? currButton.func : undefined}
                        className={classes.outlinedButtons}
                        style={currButton.style}
                      >
                        <img
                          style={{ marginRight: 7, marginLeft: 7 }}
                          src={currButton.icon}
                        />
                        <Text>{currButton.label}</Text>
                      </Button>
                    </div>
                  )
                )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const PostOldContentNoValueComponent = (props) => (
  <Text
    style={{
      ...theme.defaultFont,
      fontWeight: theme.bold,
      color: theme.brandNeutral,
    }}
  >
    {propertiesMessages.empty}
  </Text>
);

const PostOldContent = (props) => {
  const {
    post,
    classes,
    images,
    postsStageObj,
    isSafetyPost,
    postsChecklistObj,
    subCategoriesMap,
    requiredActions,
    locationTitles,
    pdfDisplay: _pdfDisplay
  } = props;
  
  const [pdfDisplay, setPdfDisplay] = useState(_pdfDisplay);
  const hidePdfDisplay = useCallback(() => setPdfDisplay(null));
  useEffect(() => {
    setPdfDisplay(_pdfDisplay);
  }, [_pdfDisplay]);

  
  const fields = [
    {
      title: issuesMessages.location,
      value: getPostLocationTitleString(locationTitles) || postsMessages.allProject,
      textValue: getPostLocationTitleString(locationTitles) || postsMessages.allProject
    },
    {
      title: issuesMessages.trade,
      value: post.getNested(["trade", "id"]),
      valueComponent: <TradeBadge ids={[post.getNested(["trade", "id"])]} />

    },
    {
      title: issuesMessages.assignToTitle,
      value: post.getNested(["assignTo", "id"]),
      shouldHide: !post.isIssue,
      valueComponent: <User
        avatarSize={25}
        style={{ marginTop: 2 }}
        companyMode={true}
        hideAvatar={false}
        detailedOverflow={!images}
        userId={post.getNested(["assignTo", "id"])}
      />
    },
    {
      title: issuesMessages.dueDateTitle,
      value: post.dueDate,
      shouldHide: !post.isIssue,
      valueComponent: <Date style={styles.postOldContentValue}      >
        {post.dueDate}
      </Date>
    },
    {
      title: checklistItemMessages.stage,
      value: postsStageObj.getCementoTitle(),
      shouldHide: !post.stageTitle,
      textValue: postsStageObj.getCementoTitle(),
    },
    {
      title: postsMenuMessages.checklist,
      value: postsChecklistObj.getCementoTitle(),
      shouldHide: !post.checklistTitle,
      textValue: postsChecklistObj.getCementoTitle(),
    },
    { 
      title: newPostMessages.attachments,
      value: postsChecklistObj.getCementoTitle(),
      shouldHide: !post.checklistTitle,
      valueComponent:<PostAttachments post={post} setPdfDisplay={setPdfDisplay} />
    },
    {
      title: newPostMessages.fine,
      value: post.getNested(["fine", "amount"]),
      shouldHide: !(post.isIssue && isSafetyPost),
      textValue: post.getNested(["fine", "amount"])
    },
    {
      title: newPostMessages.requiredAction,
      value: post.requiredAction,
      shouldHide: !(post.isIssue && isSafetyPost),
      textValue: post.getNested(
        ["requiredAction", "customText"],
        post.requiredAction?.id &&
        requiredActions?.getNested([
          post.requiredAction?.id,
          "getTitle",
        ])
        ),
    },
    {
      title: newPostMessages.subCategory,
      value: post?.subCategory?.id,
      shouldHide: !isSafetyPost,
      textValue: subCategoriesMap.getNested([
        post?.trade?.id,
        post?.subCategory?.id,
        "getTitle",
      ])
    },
    
    {
      title: newPostMessages.severity,
      value: post?.subCategory?.id,
      shouldHide: !(post.isIssue && isSafetyPost),
      valueComponent:<SafetyIssueSeverity post={post}/>
    },
  ];


  return (
    <>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          padding: theme.paddingSize,
          paddingRight: 2 * theme.paddingSize,
          paddingLeft: 2 * theme.paddingSize,
        }}
      >
        {
          _.map(fields, (field, index) => {
            const {
              title,
              value,
              shouldHide,
              valueComponent: _valueComponent,
              textValue,
            } = field;

            const valueComponent = textValue
              ? <Text style={styles.postOldContentValue}>{textValue}</Text>
              : _valueComponent;

            return (shouldHide)
              ? null
              : (
                <div key={`post_field_${index}`} style={styles.postOldContentFieldContainer}>
                  <Text className={classes.p} style={styles.postOldContentLabel}>{title}</Text>
                  {value ? valueComponent : <PostOldContentNoValueComponent />}
                </div>
              );
          })
        }
      </div>


      {Boolean(pdfDisplay) && (
        <ImageCarousel items={[{ src: pdfDisplay }]} onClose={hidePdfDisplay} pdfMode toolbar />
      )}

    </>
  );
};

const PostAttachments = (props) => {
  const { post, setPdfDisplay, rtl } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {_.map(post.attachments, (attch, i) => (
          <div
            style={{
              [`margin${rtl ? "Left" : "Right"}`]:
                theme.verticalMargin,
              cursor: "pointer",
            }}
            onClick={() =>
              setPdfDisplay(attch.uri || attch.url)
            }
          >
            <img
              src={PdfIcon}
              style={{ color: theme.brandPrimary, height: 70 }}
            />
            <Text
              values={{ number: i + 1 }}
              key={attch.uri || attch.url}
              style={{
                ...theme.defaultFont,
                fontWeight: theme.bold,
                textAlign: "center",
              }}
            >
              {attch.title ||
                newPostMessages.attachmentsDefaultName}
            </Text>
          </div>
        ))}
      </div>
    </div>);
};

const getPostLocationTitleString = (locationTitles) => {
  return [
    locationTitles?.buildingTitle,
    locationTitles?.floorTitle,
    locationTitles.unitTitle
  ]
    .filter(str => str?.length)
    .join(' / ');
};

const SafetyIssueSeverity = (props) => {
  const { post } = props;
  return (
    <div style={{ display: "flex" }}>
      <div style={{ display: "flex", width: 60, height: 20 }}>
        {severityColors.map((curr) => (
          <Text
            key={curr.val}
            style={{
              ...theme.defaultFont,
              fontWeight: theme.bold,
              flex: 1,
              textAlign: "center",
              alignSelf: "center",
              backgroundColor: curr.color,
              opacity: post.severity == curr.val ? 1 : 0.4,
              border:
                post.severity == curr.val
                  ? "1px solid " + theme.brandNeutralDark
                  : "none",
            }}
          >
            {curr.val}
          </Text>
        ))}
      </div>
      <Text
        style={{
          ...theme.defaultFont,
          fontWeight: theme.bold,
          margin: "0px 5px",
        }}
      >
        {safetyMessages.severity.title[post.severity]}
      </Text>
    </div>
  );
};

const PostTimeLine = injectIntl((props) => {
  const {
    story,
    rtl,
    viewer,
    selectedProjectId,
    post,
    openImagesModal,
  } = props;

  const sortedStory = useMemo(() => {
    return _.sortBy(story, (s) => s.createdAt);
  }, [story]);

  return (
    <div
      style={{
        flexDirection: "column",
        padding: 2 * theme.paddingSize,
        paddingTop: theme.paddingSize,
      }}
    >
      <Text style={styles.postOldContentLabel}>
        {issuesMessages.story.title}
      </Text>
      {sortedStory.map((s, storyIndex, arr) => {
        let Title = s.title;
        let SubTitle = s.subTitle;
        let Content = s.content;
        let lastStory = storyIndex == arr.length - 1;
        let StoryIcon = storyTypesImages[s.type] ? (
          <img src={storyTypesImages[s.type]} />
        ) : (
          <LabelImportantRounded />
        );
        
        return (
          <div data-qa="post-comment" key={s.id + s.createdAt} style={{ display: "flex" }}>
            <div
              style={{
                width: 25,
                [rtl ? "marginRight" : "marginLeft"]: 8,
                [rtl ? "borderRight" : "borderLeft"]: lastStory
                  ? ""
                  : theme.borderLineNeutral + "80",
              }}
            >
              <div
                style={{
                  display: "flex",
                  color: theme.brandPrimary,
                  position: "relative",
                  [rtl ? "right" : "left"]: -8,
                }}
              >
                {StoryIcon}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
              }}
            >
              <div style={{ flexDirection: "column" }}>
                <div
                  style={{
                    ...theme.defaultFont,
                    fontSize: 13,
                    lineHeight: "13px",
                    fontWeight: theme.strongBold,
                  }}
                >
                  {Title}
                </div>
                <div
                  style={{
                    ...theme.subFont,
                    flexDirection: "row",
                    display: "flex",
                    fontSize: 12,
                  }}
                >
                  {SubTitle}
                  {Boolean(
                     ![storyTypes.new, storyTypes.attachedFile].includes(s.type) && viewer && viewer.adminMode == 1 
                  ) && (
                      <div
                        data-qa="delete-comment-button"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          [rtl ? "paddingLeft" : "paddingRight"]: 5,
                        }}
                        onClick={() =>
                          deleteComment(
                            selectedProjectId,
                            "post",
                            post.id,
                            s.id
                          )
                        }
                      >
                        <div
                          style={{
                            cursor: "pointer",
                            marginLeft: 5,
                            marginRight: 5,
                          }}
                        >
                          <img
                            style={{ width: 13, height: 14 }}
                            src={trash}
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <div
                style={{
                  ...theme.defaultFont,
                  fontWeight: theme.bold,
                  color: theme.brandNeutralDark,
                  marginTop: theme.paddingSize,
                  marginBottom: theme.paddingSize * 2,
                  flexDirection: "column",
                }}
              >
                {Content}
                <ImageTileList
                  imageURIs={s.imageURIs}
                  onImageClick={({i}) => openImagesModal(storyIndex, i)}
                  customCtnStyle={{
                    marginTop: Content ? theme.paddingSize / 3 : 0,
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});

const PostSaveButtons = (props) => {
  const {
    post,
    savePost,

  } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 15,
      }}
    >
      <div
        onClick={() => savePost?.()}
        style={{
          cursor: "pointer",
          margin: 5,
          border: "1px solid #00000010",
          backgroundColor: theme.backgroundColorBright,
          color: theme.brandPrimary,
          padding: "5px 15px",
          borderRadius: 25,
          boxShadow: "#00000015 0px 0px 10px 0px",
        }}
      >
        <Text>{newPostMessages.savePost}</Text>
      </div>
      {
        Boolean(!post?.checklistItemInstance) && (
          <div
            onClick={() => savePost?.(true)}
            style={{
              cursor: "pointer",
              margin: 5,
              border: "1px solid #00000010",
              backgroundColor: theme.backgroundColorBright,
              color: theme.brandPrimary,
              padding: "5px 15px",
              borderRadius: 25,
              boxShadow: "#00000015 0px 0px 10px 0px",
            }}
          >
            <Text>{newPostMessages.saveAndRepost}</Text>
          </div>
        )
      }
    </div >
  );
};