import { Record } from '../transit';
import * as projectStages from './projectStages';

const Project = Record({
  createdAt: null,
  lastUpdate: null,
  counter: null,
  id: null,
  address: null,
  city: null,
  description: null,
  images: null,
  companyId: null,
  company: null,
  title: null,
  owner: {},
  location: {},
  permissions: null,
  members: null,
  deletedMembers: null, // Use to load the deatails 
  type: null,
  stage: projectStages.CONSTRUCTION,
  tzLocation: null,
  lang: null,
  showOnAdmin:null,
  region: null,
}, 'project');

export default Project;
