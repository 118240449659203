import React, { Component, useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import Post from '../views/Posts/Post';
import ObjectPropertiesPage from '../views/Properties/ObjectPropertiesPage';
import ChecklistsCard from '../views/Checklists/ChecklistsCard';
import ChecklistItemCard from '../views/Checklists/ChecklistItemCard';
import DrawingCard from '../views/Drawings/DrawingCard';
import UsersManagementCard from '../views/Members/UsersManagementCard';
import AddNewCertification from '../views/Properties/AddNewCertification';
import { CardHeader, CardBody, CardTabs } from '../components';
import Text from '../components/CementoComponents/Text';

import { PrintOutlined } from '@material-ui/icons';
import editPen from '../assets/img/icons/editPen.png';
import trash from '../assets/img/icons/trash.png';

import theme from '../assets/css/theme';
import postsMessages from '../../common/posts/postsMessages';
import issuesMessages from '../../common/issues/issuesMessages';
import systemMessages from '../../common/app/systemMessages';
import TradeBadge from '../components/CementoComponents/TradeBadge';
import * as issueStates from '../../common/issues/issueStates.js';
import MenuScrollbar from '../components/CementoComponents/MenuScrollbar';
import NothingToDisplay from '../components/CementoComponents/NothingToDisplay';
import DotStatusText from '../components/CementoComponents/DotStatusText';
import ObjectPropertiesPageUI from '../views/Properties/ObjectPropertiesPageUI/ObjectPropertiesPageUI';
import close from '../assets/img/icons/close.png';
import back from '../assets/img/icons/back.png';
import {ChecklistItemInstancesHOC} from '../../common/posts/hooks/useChecklistItemInstances';
import { draftValidator } from '../../common/ui/actions.js';
import Button from "../../web/app/standardComponents/Button.js"
/**
 * @typedef {'_blankCard' | 'post' | 'forms' | 'objectProperties' | 'connectedObjectProperties' | 'addCertification' | 'stage' | 'checklists' | 'checklistItem' | 'drawing' | 'projectStructure' | 'member' | 'company' | 'locationsGroup'} CardTypes
 * @typedef {{ props?: object, type: CardTypes }} SideObject
 * @typedef SideCardProps
 * @property {SideObject} sideObject
 * @property {boolean} [editMode]
 * @property {(newState?: boolean) => void} [onEditModeChange]
 * @property {boolean} [showBackButton]
 * @property {boolean} [showCloseButton]
 * @property {() => void} [onBackOrClose]
 *
 * @extends React.Component<SideCardProps>
 */
class SideCard extends Component {
	constructor(props) {
		super(props);
		this.onLoad = this.onLoad.bind(this);
		this.handleEditModeChange = this.handleEditModeChange.bind(this);
		this.handleSave = this.handleSave.bind(this);

		this.initialState = {
			isCreationMode: false,
			contentType: null,
			headerParams: null,
			tabsParams: null,
			loadTS: null,
		};

		this.state = this.initialState;
	}
	
	componentDidMount(){
		const { onRef, sideObject } = this.props;
		if (onRef) onRef(this);

		const isCreation = sideObject?.props?.creation;
		if (isCreation) {
			this.handleEditModeChange(true, isCreation);
		}
	}


	componentWillUnmount() {
		const { onRef } = this.props;
		if (onRef) onRef(null);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.isValDiff(nextProps, ['sideObject', 'type'])) this.setState(this.initialState);
	}

	onLoad(headerParams, tabsParams, editMode, isCreationMode, contentType = '') {
		let stateChanges = {
			loadTS: new Date().getTime(),
			headerParams,
			tabsParams,
			contentType,
			isCreationMode: Boolean(isCreationMode),
		};

		if (stateChanges.isCreationMode && !this.state.isCreationMode) {
			this.handleEditModeChange(true, true);
		} else if (_.isBoolean(editMode) && editMode !== this.props.editMode) {
			this.handleEditModeChange(editMode, false);
		}

		this.setState(stateChanges);
	}

	async handleSave() {
		const { headerParams = {} } = this.state;
		const { onSave } = headerParams || {};

		const success = onSave && (await onSave());
		if (success !== false) this.handleEditModeChange(false);
	}

	handleEditModeChange(bool, isCreationMode = false, contentType) {
		const { onEditModeChange } = this.props;

		let stateChanges = { isCreationMode };
		if (contentType) stateChanges.contentType = contentType;
		if (onEditModeChange) {
			onEditModeChange(bool, () => {
				this.setState(stateChanges);
			}) 
		} else this.setState(stateChanges);
	}

	getPostStateBadgeParams = postState => {
		let statusText = postsMessages.documentation;
		let statusColor = theme.brandNeutral;

		switch (postState) {
			case issueStates.ISSUE_STATE_OPENED: {
				statusText = issuesMessages.openStatus;
				statusColor = theme.brandDanger;
				break;
			}
			case issueStates.ISSUE_STATE_RESOLVED: {
				statusText = issuesMessages.resolveStatus;
				statusColor = theme.brandWarning;
				break;
			}
			case issueStates.ISSUE_STATE_CLOSED: {
				statusText = issuesMessages.closeStatus;
				statusColor = theme.brandSuccess;
				break;
			}
		}

		return { statusText, statusColor };
	};

	handleSetHeaderParams = newHeaderParams => {
		const { headerParams } = this.state;

		this.setState({ headerParams: Object.assign({}, headerParams, newHeaderParams) });
	};

	handleCancel = async () => {
		const { onCancel } = this.state.headerParams || {};
    const { draftValidator, editMode } = this.props;
    let didCancel = true;
		
    if (onCancel) {
      didCancel = await new Promise((resolve, reject) => {
        draftValidator(
          async () => {
            const isCancel = await onCancel();
            resolve(isCancel);
          },
          () => resolve(false)
        );
      });
    }

    if (didCancel) {
      this.handleEditModeChange(false);
    }

    return didCancel || !editMode;
	};

	handleBackOrClose = async () => {
    const { onBackOrClose, sideObject, editMode } = this.props;
    const { headerParams } = this.state;

    if (editMode) {
      const didCancel = await this.handleCancel();
      if (!didCancel) return;
    }

    onBackOrClose?.();
    headerParams.onBackOrClose?.();
    sideObject.props?.onBackOrClose?.();
  }

	CloseOrBackButton = () => {
		const { showBackButton, showCloseButton, rtl } = this.props;

		let closeOrBack = null;

		if (!(showBackButton || showCloseButton)) {
			return closeOrBack;
		}

		const NavIcon =
			showBackButton ? (
				<img style={{ transform: 'rotate(' + (!rtl ? 0 : 180) + 'deg)' }} src={back} />
			) : (
				<img src={close} />
			);
		

		closeOrBack = (
			<div
				onClick={() => this.handleBackOrClose()}
				style={{
					height: theme.headerHeightSecondary,
					fontSize: theme.fontSizeH6,
					lineHeight: theme.fontSizeH6 + 'px',
					margin: '0px 15px',
					cursor: 'pointer',
					position: 'absolute',
					zIndex: 2000,
					[rtl ? 'left' : 'right']: 0,
					display: 'flex',
					alignItems: 'center',
				}}
			>
				{NavIcon}
			</div>
		);

		return closeOrBack;
	};

	render() {
		const { headerParams, tabsParams, loadTS, contentType, isCreationMode } = this.state;
		const {
			sideObject, rtl, intl,
			children, editMode, isCreateMode,
			viewType, hideCancel, isForceUpdateEnabled,
			isEditAllowed = true,
			isMain,
		} = this.props; //viewType == 'modal' || 'side'
		const {
			onPrint,
			editable,
			onDelete,
			title,
			tradeIds,
			postState,
			onCancel,
			statusBadgeParams,
			middleComponent: headerMiddleComponent,
		} = headerParams || {};

		if (!sideObject) return children && children({});
		let header, alert, tabs;
		let objectName = _.get(sideObject, ['props', 'objectName'], contentType);
		objectName =
			_.has(objectName, 'id') && _.has(objectName, 'defaultMessage') ? intl.formatMessage(objectName) : objectName;

		const headerTextStyle = Object.assign(
			{},
			styles.headerText,
			{ [rtl ? 'marginLeft' : 'marginRight']: theme.paddingSize },
			viewType === 'modal' && { fontSize: styles.editModeTitle.fontSize },
		);

		let badgeParams = statusBadgeParams;
		if (postState) badgeParams = this.getPostStateBadgeParams(postState);

		let status = null;
		if (badgeParams) {
			const { statusText, statusColor } = badgeParams;
			const badgeSize = viewType === 'modal' ? 10 : 7;
			status = (
				<DotStatusText
					label={statusText}
					dotStyle={{
						height: badgeSize,
						width: badgeSize,
						marginLeft: theme.margin / 3,
						marginRight: theme.margin / 3,
					}}
					labelStyle={headerTextStyle}
					color={statusColor}
				/>
			);
		}
		
		header = (
			<>
			<this.CloseOrBackButton />
			<CardHeader style={styles.headerContainer}>
				<div style={{ display: 'flex' }}>
					{Boolean(!editMode) && status}
					<div style={{ flex: 1 }}>
						{Boolean(editMode) && Boolean(objectName)
							? <Text style={styles.editModeTitle} values={{ contentType: objectName }}>
									{systemMessages[`${isCreationMode ? 'add' : 'edit'}Object`]}
								</Text>
							: Boolean(title) && <Text style={headerTextStyle}>{title}</Text>}
					</div>
				</div>

				{Boolean(headerMiddleComponent) && (
					<div style={{ display: 'flex', flex: 1, justifyContent: 'center', height: '100%', alignItems: 'center' }}>
						{headerMiddleComponent}
					</div>
				)}

				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						[`margin${rtl ? 'Left' : 'Right'}`]: theme.margin / 3,
						justifyContent: 'flex-end'
					}}
				>
					{Boolean((tradeIds || []).length > 0) && <TradeBadge ids={tradeIds} />}

					{((headerParams || {}).customIcons || []).map(icon => {
						if (icon.onClick && icon.component && (!editMode || icon.showOnEditMode))
							return (
								<div style={styles.icon} onClick={icon.onClick}>
									{icon.component}
								</div>
							);
					})}
					{Boolean((headerParams || {}).onSign) && (
						<Button
							onClick={headerParams.onSign}
							shouldInvertColors={true}>

							{intl.formatMessage(systemMessages.sign)}

						</Button>
					)}
					{Boolean(onPrint && !editMode) && (
						<PrintOutlined data-qa="print-button" style={{ fontSize: 16, lineHeight: '16px', ...styles.icon }} onClick={onPrint ? onPrint : undefined} />
					)}

					{Boolean(onDelete && !editMode) && (
						<div data-qa="delete-button" style={styles.icon} onClick={onDelete ? onDelete : undefined}>
							<img style={{ width: 16, height: 16, marginTop: -4 }} src={trash} />
						</div>
					)}

					{Boolean(isEditAllowed && editable) && (
						<div style={{ display: 'flex', height: '100%', alignItems: 'center', ...styles.icon }}>
							{editMode ? (
								<>
									{Boolean(onCancel) && !hideCancel && (
										<Text style={{ margin: 5, fontSize: 16 }} onClick={this.handleCancel}>
											{systemMessages.cancel}
										</Text>
									)}
									<Text data-qa="save-after-edit-button" style={{ color: theme.brandPrimary, margin: 5, fontSize: 16 }} onClick={this.handleSave}>
										{systemMessages.save}
									</Text>
								</>
							) : (
								<img data-qa="edit-button" style={{ width: 13, height: 15 }} src={editPen} onClick={() => this.handleEditModeChange(true)} />
							)}
						</div>
					)}
				</div>
			</CardHeader>
			</>
		);
		if (headerParams && headerParams.alert)
			alert = (
				<CardHeader style={{ ...styles.headerContainer, justifyContent: 'center' }}>
					<Text style={{ color: 'red' }}>{headerParams.alert}</Text>
				</CardHeader>
			);

		// if (!editMode && tabsParams && (tabsParams.tabs || []).length)
		// 	tabs = (
		// 		<CardHeader style={styles.tabsContainer}>
		// 			<CardTabs
		// 				maxLines={1}
		// 				maxChars={15}
		// 				key={`CardTabs${loadTS}`}
		// 				onClick={tabsParams.onTabSelect}
		// 				tabs={tabsParams.tabs}
		// 			/>
		// 		</CardHeader>
		// 	);

		return (
			children &&
			children({
				header,
				alert,
				tabs,
				cardBody: (
					<InnerSideCardBody
						sideObject={sideObject}
						editMode={editMode}
						onCardLoad={this.onLoad}
						changeEditMode={this.handleEditModeChange}
						setCardHeaderParams={this.handleSetHeaderParams}
						isCreateMode={isCreateMode}
						hideCancel={hideCancel}
						isForceUpdateEnabled={isForceUpdateEnabled}
						isMainCard={isMain}
					/>
				),
			})
		);
	}
}

SideCard = injectIntl(SideCard);
const enhance = compose(
  connect(
    (state) => ({
      rtl: state.app.rtl,
    }),
    { draftValidator }
  )
);

export default enhance(SideCard);

const styles = {
	headerContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		minHeight: theme.headerHeightSecondary,
		paddingLeft: 2 * theme.paddingSize,
		paddingRight: 2 * theme.paddingSize,
		backgroundColor: theme.backgroundColor,
		borderBottom: theme.borderLineHeaderInfo,
	},
	headerText: {
		flex: 1,
		...theme.subFont,
		color: '#2e231d',
		fontSize: theme.fontSize,
		fontWeight: theme.strongBold,
		width: 'auto',
	},
	editModeTitle: {
		color: theme.brandPrimary,
		fontWeight: theme.bold,
		fontSize: theme.fontSizeH5,
	},
	tabsContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: theme.headerHeightSecondary,
		backgroundColor: theme.backgroundColorBright,
		borderBottom: '2px solid ' + theme.brandNeutralLight + '30',
		paddingLeft: 2 * theme.paddingSize,
		paddingRight: 2 * theme.paddingSize,
	},
	icon: {
		marginLeft: theme.margin / 3,
		marginRight: theme.margin / 3,
		cursor: 'pointer',
	},
};

const InnerSideCardBody = props => {
	const {
		sideObject, editMode, isCreateMode,
		onCardLoad, changeEditMode, setCardHeaderParams,
		hideCancel, isForceUpdateEnabled, isMainCard
	} = props;
	const { type, props: sideObjectProps, Component } = sideObject;

	const originalEditMode = sideObjectProps.editMode;
	const cardProps = {
		...sideObjectProps,
		originalEditMode,
		editMode,
		onCardLoad,
		changeEditMode,
		setCardHeaderParams,
		isCreateMode,
		hideCancel,
		isForceUpdateEnabled,
		isMainCard
	};

	const { selectedCell } = cardProps;
	let card = <></>;

	switch (type) {
		case 'post':
			card = <Post key={'postcard'} {...cardProps} />;
			break;
		case 'forms':
		case 'connectedObjectProperties':
			// This version of the object properties page is connected directly to the reducer
			// and handles saving of the instances logic
			card = <ObjectPropertiesPage useCollapsibleSections={type === 'forms'} {...cardProps} />;
			break;
		case 'objectPropertiesPage': {
			// This version of the object properties page receive all of the properties mechanism as props
			// and relies on the user of the page to handle all of the saving, deleting logic and other things
			card = <ObjectPropertiesPageUI {...cardProps} />;
			break;
		}
		case 'addCertification':
			card = <AddNewCertification {...cardProps} />;
			break;
		case 'stage': // TODO: create stage side card
			break;
		case 'checklists':
			if (selectedCell) {
				Object.assign(cardProps, {
					..._.pick(selectedCell, ['posts', 'locationsData', 'isAggregatedCell', 'alert']),
					selectedChecklists: selectedCell.checklists,
					selectedChecklistItems: selectedCell.checklistItems,
				});
			}
			card = <ChecklistsCard {...cardProps} />;
			break;
		case 'checklistItem':
			card = (
        <ChecklistItemInstancesHOC
          projectId={cardProps.projectId}
          checklistId={cardProps.checklist?.id}
          checklistItemId={cardProps.checklistItem?.id}
          locationId={cardProps.locationId}>
          {({ checklistInstances, checklistInstancesArr }) => (
            <ChecklistItemCard
              {...cardProps}
              checklistInstances={checklistInstances}
              checklistInstancesArr={checklistInstancesArr}
            />
          )}
        </ChecklistItemInstancesHOC>
      );
			break;
		case 'drawing':
			card = <DrawingCard {...cardProps} />;
			break;
		case 'member':
		case 'company':
				card = <UsersManagementCard {...cardProps} />;
				break;
		case 'nothingToDisplay':
			card = <NothingToDisplay />;
			break;

		case '_blankCard': // WARNING !!! if using _blankCard, you must provide a "componentId" so the card doesnt rerender completely at every render
			card = <BlankCard originalEditMode={originalEditMode} Component={Component} cardProps={cardProps} />;
			break;

		default:
			card = null;
			break;
	}

	return <CardBody style={{ maxHeight: '100%', height: '100%' }}>{card}</CardBody>;
};

const BlankCard = props => {
	const { Component, originalEditMode, cardProps = {} } = props;
	const { headerParams, tabsParams, isCreationMode, contentType, onCardLoad, componentId, noScrollBars } = cardProps;

	const [node, setNode] = useState(null);

	// will/did Mount
	useEffect(() => {
		if (onCardLoad) onCardLoad(headerParams, tabsParams, originalEditMode, isCreationMode, contentType);
	}, []);

	useEffect(() => {
		setNode(React.createElement(Component, cardProps, null));
	}, [componentId, cardProps.editMode]);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: 'inherit', width: 'inherit' }}>
			{Boolean(headerParams && headerParams.title && !headerParams.noTitle) && (
				<Text
					style={{ fontSize: theme.fontSizeH4, fontWeight: 'bold', alignSelf: 'flex-start', padding: theme.margin * 2 }}
				>
					{headerParams.title}
				</Text>
			)}
			{noScrollBars ? (
				<div style={{ flex: 1 }}>{node}</div>
			) : (
				<MenuScrollbar scrollbarsStyle={{ overflowX: 'hidden', flex: 1 }} isSmooth={true}>
					{node}
				</MenuScrollbar>
			)}
		</div>
	);
};
