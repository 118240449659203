const lastDeploy = ` ------------------------------------------------------------------------- 
 ------------------------------------------------------------------------- 
      Welcome to cemento! Last update - 25/12/2024, 17:49:30
      Version No.  0.99.5.2.80
 ------------------------------------------------------------------------- 
 ------------------------------------------------------------------------- 
`;
const date = '25/12/2024, 17:49:30';
const version = '0.99.5.2.80';

module.exports = {
    lastDeploy,
    date,
    version,
};
