import _ from 'lodash';
import * as instancesStatus from '../checklistItemsInstances/clItemInstanceStatus';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import serverSDK from '@cemento-sdk/server';
import { ERROR_CODES } from '../app/actions';
import { onError } from '../app/funcs';
import { getNewId } from '../lib/api';
import ExtraError from '../lib/errors/extraError';
import { getAppState, getDispatch, lokiInstance, realmInstance } from '../configureMiddleware';
import { platformActions } from '../platformActions';
import { updateRetryRealm } from '../lib/realm/funcs';
import { uploadPropertiesInstances } from '../propertiesInstances/actions';

import {
  CHECKLIST_ITEMS_INSTANCES_EVENTS,
  CHECKLIST_ITEM_INSTANCES_COLLECTION_NAME,
} from './trackChecklistItemsInstances';
import {
  debugParams,
  notifyLongFunctionDuration,
  removeNilAndEmpty,
  replaceMaxUpdateTSIfNeeded,
  subscribeToLastUpdates,
} from '../lib/utils/utils';

let bouncerCollector = {};

const CHECKLIST_ITEM_INSTANCE_FIELDS = [
  'id',
  'checklistItemId',
  'status',
  'updatedTS',
  'targetTS',
  'isDeleted',
  'locationId',
  'checklistId',
  'extraData',
  'parent',

];

export const getChecklistItemsInstances = async (viewer, projectId, cleanAll) => {
  const dispatch = getDispatch();

  if (cleanAll) {
    setChecklistInstanceValues({
      projectId,
      cleanAll,
      forceLocalSave: true,
    });
  }

  const scopeParams = {
    scope: 'projects',
    scopeId: projectId,
  };

  const resourceParams = {
    subject: 'checklistInstances',
    queryParams: {
      subjectName: 'itemInstances',
    },
    getLastUpdateTS: () => getLastUpdateTS(realmInstance, lokiInstance, projectId),
    getData: (lastUpdateTS) => {
      dispatch({ type: CHECKLIST_ITEMS_INSTANCES_EVENTS.FETCH_CHECKLIST_ITEM_INSTANCES, payload: { projectId } });
      return serverSDK.checklists.getChecklistItemInstances({ projectId, lastUpdateTS, fields: CHECKLIST_ITEM_INSTANCE_FIELDS });
    },
    isObjectUseTransactionalDB: true,
  };

  const onData = (_data, isRevoke) => {
    dispatch({
      type: CHECKLIST_ITEMS_INSTANCES_EVENTS.CHECKLIST_ITEM_INSTANCES_DONE_LOADING,
      payload: { scopeId: projectId },
    });
    if (debugParams.disableFetchByTSSave) return;

    const debounceSaveParams = {
      checklistItemsInstances: _.map(_data, instance => ({ ...instance, projectId })),
      projectId,
      immediateSave: false,
      cleanAll: isRevoke,
    };
    return prepareDebouncingSave(debounceSaveParams);
  };

  subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData);
};

export const getChecklistItemInstanceById = async ({ id }) => {
  return await serverSDK.checklists.getChecklistItemInstance({ id });
};

export const upsertChecklistItemInstance = async ({
  projectId,
  inChecklistItemInstance,
  instanceParentChecklistId,
  issueCreationIndication,
  totalItems,
  immediateSave,
  extraData,
}) => {
  if (!projectId || !inChecklistItemInstance) return;

  let checklistItemInstance;

  if (inChecklistItemInstance.isNew) {
    checklistItemInstance = createNewChecklistItemInstance(projectId, inChecklistItemInstance);
  } else {
    checklistItemInstance = getChecklistItemInstance(inChecklistItemInstance.id, projectId);
    checklistItemInstance = { ...checklistItemInstance, ...inChecklistItemInstance };
  }

  checklistItemInstance.projectId = projectId;

  if (extraData) checklistItemInstance.extraData = await uploadExtraDataInstances(projectId, extraData);

  checklistItemInstance = removeNilAndEmpty(checklistItemInstance, 'upsertChecklistItemInstance');

  let localClone = { ...checklistItemInstance.realmToObject() };
  if (immediateSave) {
    const debounceSaveParams = {
      checklistItemsInstances: { '0': localClone },
      projectId,
      immediateSave: true,
      cleanAll: false,
      forceLocalSave: true,
    };
    prepareDebouncingSave(debounceSaveParams);
  }

  let shouldSaveLocally = true;

  try {
    await serverSDK.checklists.upsertChecklistItemInstance(checklistItemInstance);
  } catch (error) {
    shouldSaveLocally = await handleSdkError({ error, projectId, instances: [checklistItemInstance] });
  }

  if (shouldSaveLocally) {
    const debounceSaveParams = {
      checklistItemsInstances: {
        '0': {
          ...checklistItemInstance,
          isLocal: true,
        },
      },
      projectId,
      immediateSave: false,
    };
    prepareDebouncingSave(debounceSaveParams);

    const dispatch = getDispatch();
    dispatch({
      type: CHECKLIST_ITEMS_INSTANCES_EVENTS.UPSERT_CHECKLIST_ITEM_INSTANCE,
      payload: { checklistItemInstance, projectId, totalItems, instanceParentChecklistId, issueCreationIndication },
    });
  }

  return { checklistItemInstance, projectId, totalItems, instanceParentChecklistId, issueCreationIndication };
};

export const uploadFailedChecklistItemInstance = async (checklistItemInstance) => {
  const dispatch = getDispatch();

  let newChecklistItemInstance;
  let projectId = checklistItemInstance.projectId;

  let didUpload = false;
  let willRetry = false;
  if (projectId && checklistItemInstance) {
    newChecklistItemInstance = { ...checklistItemInstance.realmToObject() };
    if (newChecklistItemInstance.extraData) {
      let extraData = {};
      newChecklistItemInstance.extraData.loopEach((key, currExtraData) => {
        const { id, instanceId } = currExtraData;
        extraData[id] = { id, instanceId };
      });
      newChecklistItemInstance.extraData = extraData;
    }

    newChecklistItemInstance = removeNilAndEmpty(newChecklistItemInstance);

    let shouldSaveLocally = true;
    try {
      await serverSDK.checklists.upsertChecklistItemInstance(newChecklistItemInstance);
      didUpload = true;
    } catch (error) {
      shouldSaveLocally = await handleSdkError({ error, projectId, instances: [checklistItemInstance] });
      willRetry = shouldSaveLocally;
    }

    if (shouldSaveLocally) {
      const debounceSaveParams = {
        checklistItemsInstances: {
          '0': {
            ...checklistItemInstance,
            isLocal: true,
          },
        },
        projectId,
        immediateSave: false,
      };
      prepareDebouncingSave(debounceSaveParams);

      dispatch({
        type: CHECKLIST_ITEMS_INSTANCES_EVENTS.UPSERT_CHECKLIST_ITEM_INSTANCE,
        payload: { checklistItemInstance: newChecklistItemInstance, projectId },
      });
    }
  }

  return { checklistItemInstance: newChecklistItemInstance, projectId, didUpload, willRetry };
};

export const getAllFailedToUploadChecklists = async (locationId) => {
  try {
    const viewer = getAppState().users.viewer;
    if (platformActions.app.getPlatform() !== 'web') {
      let localChecklistItemsInstance = [];
      if (viewer) {
        let realmRetry = realmInstance.retry_checklistItemsInstances;
        let uploadedLocalChecklistItemsInstance = realmRetry
          .objects('checklistItemsInstance1')
          .filtered('isLocal = false');

        if (uploadedLocalChecklistItemsInstance.length) {
          await realmRetry.beginTransactionMutex();
          try {
            realmRetry.delete(uploadedLocalChecklistItemsInstance);
            realmRetry.commitTransactionMutex();
          } catch (e) {
            realmRetry.cancelTransactionMutex();
            console.log(e);
            throw e;
          }
        }

        let query = 'isLocal = true';
        if (locationId) query += ` AND locationId = "${locationId}"`;

        localChecklistItemsInstance = realmInstance.retry_checklistItemsInstances
          .objects('checklistItemsInstance1')
          .filtered(query)
          .sorted('createdTS');
        return { localChecklistItemsInstance, counter: localChecklistItemsInstance.length };
      }
      return { localChecklistItemsInstance: [], counter: 0 };
    }
  } catch (error) {
    platformActions.sentry.notify('getAllFailedToUploadChecklists error', { error });
    throw error;
  }
};

export const bulkStatusUpdate = async ({
  newStatus,
  projectId,
  locationId,
  allRelevantItems = [],
  allRelevantInstances,
  immediateSave,
  checklistId,
  timeFilter,
}) => {
  let map = {};
  let checklistItemInstances = [];
  let instancesByItems = {};
  let requirementsExists = false;
  let didSetEmptyCell = false;

  for (let inst of allRelevantInstances) {
    if (inst) instancesByItems[inst.checklistItemId] = inst;
  }

  for (let item of allRelevantItems) {
    if (!item) continue;
    let currRequirementExists = _.values(item.requirements).filter(Boolean).length > 0 && !instancesByItems[item.id];
    let currExtraDataRequirementExists =
      _.values(item.extraData).filter((prop) => !prop.optional && !prop.readOnly).length > 0;
    requirementsExists = requirementsExists || currRequirementExists || currExtraDataRequirementExists;
    if (currRequirementExists || currExtraDataRequirementExists) continue;
    if (!instancesByItems[item.id]) {
      let newInstance = createNewChecklistItemInstance(projectId, {
        status: newStatus,
        locationId,
        checklistId,
        checklistItemId: item.id,
        targetTS: timeFilter,
      });
      delete newInstance['isLocal'];
      map[newInstance.id] = Object.assign({}, newInstance, { isLocal: true });
      checklistItemInstances.push(newInstance);
      didSetEmptyCell = true;
    } else if (
      !instancesByItems[item.id].status ||
      instancesByItems[item.id].status === instancesStatus.CLI_STATUS_NONE
    ) {
      let clone = instancesByItems[item.id].toJS ? instancesByItems[item.id].toJS() : { ...instancesByItems[item.id] };
      didSetEmptyCell = true;
      clone.updatedTS = null;
      clone.status = newStatus;
      delete clone['isLocal'];
      checklistItemInstances.push(clone);
      map[clone.id] = Object.assign({}, clone, { isLocal: true });
    }
  }

  const debounceSaveParams = {
    checklistItemsInstances: map,
    projectId,
    immediateSave: true,
    forceLocalSave: true,
  };
  if (immediateSave) prepareDebouncingSave(debounceSaveParams);

  const dispatch = getDispatch();
  dispatch({
    type: CHECKLIST_ITEMS_INSTANCES_EVENTS.UPDATE_BULK_STATUS,
    payload: {
      didCompleteChecklist: Boolean(!requirementsExists && didSetEmptyCell),
      requirementsExists,
      projectId,
      newStatus,
      locationId,
    },
  });

  let shouldSaveLocally = true;

  if (checklistItemInstances.length) {
    try {
      await serverSDK.checklists.upsertBulkChecklistItemInstance({ instances: checklistItemInstances });
    } catch (error) {
      shouldSaveLocally = handleSdkError({ error, projectId, instances: checklistItemInstances });
    }

    if (shouldSaveLocally) {
      let newMap = {};
      Object.keys(map || {}).forEach((instanceId) => {
        newMap[instanceId] = Object.assign({}, map[instanceId], { isLocal: false });
      });
      const debounceSaveParams = {
        checklistItemInstances: newMap,
        projectId,
        immediateSave: false,
      };
      prepareDebouncingSave(debounceSaveParams);
    }
  }

  return {
    didCompleteChecklist: Boolean(!requirementsExists && didSetEmptyCell),
    requirementsExists,
    projectId,
    newStatus,
    locationId,
  };
};

export const createNewChecklistItemInstance = (projectId, inChecklistItemInstance) => {
  if (!(projectId && inChecklistItemInstance && inChecklistItemInstance.checklistItemId))
    throw new ExtraError('createNewChecklistItemInstance - missing values', { projectId, inChecklistItemInstance });

  let checklistItemInstance = _.clone(inChecklistItemInstance.realmToObject());
  checklistItemInstance.id = inChecklistItemInstance.id || getNewId();
  checklistItemInstance.projectId = projectId;
  checklistItemInstance.isLocal = true;
  checklistItemInstance.createdTS = Date.now();
  delete checklistItemInstance.isNew;
  return checklistItemInstance;
};

const uploadExtraDataInstances = async (projectId, extraData) => {
  const dispatch = getDispatch();
  const extraDataInstancesBySubjectName = _.groupBy(extraData, (item) => item.subjectName);

  let uploadedInstancesArr = [];
  await Promise.all(
    _.entries(extraDataInstancesBySubjectName).map(async ([subjectName, instances]) => {
      const uploadActionRes = await dispatch(uploadPropertiesInstances(projectId, instances, subjectName, false));
      uploadedInstancesArr = uploadedInstancesArr.concat(uploadActionRes.instances);
    })
  );

  const mappedExtraDataInstances = uploadedInstancesArr.reduce(
    (acc, instance) => _.set(acc, instance.propId, { id: instance.propId, instanceId: instance.id }),
    {}
  );

  return mappedExtraDataInstances;
};

const webQuery = ({ projectId, locationId = null, checklistId = null, instanceIds = null }) => {
    // Build LokiJS query dynamically
    const lokiQuery = { projectId };

    if (locationId) lokiQuery.locationId = locationId;
    if (checklistId) lokiQuery.checklistId = checklistId;
    if (instanceIds && instanceIds.length > 0) {
      lokiQuery.id = { $in: instanceIds }; // Filter by array of IDs
    }

    return lokiInstance
      .getCollection(CHECKLIST_ITEM_INSTANCES_COLLECTION_NAME)
      .cementoFind(lokiQuery);
}

const nativeQuery = ({ projectId, locationId = null, checklistId = null, instanceIds = null }) => {
    // Build Realm query dynamically
    let realmQuery = `projectId = "${projectId}"`;
    if (locationId) realmQuery += ` AND locationId = "${locationId}"`;
    if (checklistId) realmQuery += ` AND checklistId = "${checklistId}"`;
    if (instanceIds && instanceIds.length > 0) {
      const instanceIdsQuery = instanceIds.map(id => `"${id}"`).join(',');
      realmQuery += ` AND id IN {${instanceIdsQuery}}`;
    }

    return realmInstance.checklistItemsInstances
      .objects('checklistItemsInstance1')
      .filtered(realmQuery);
}

export const queryChecklistItemInstances = (filterObj) => {
  
  if (!filterObj.projectId) {
    throw new Error('Project ID is required');
  }

  if (platformActions.app.getPlatform() === 'web') {
    return webQuery(filterObj);
  } else {
    return nativeQuery(filterObj);
  }

};


const getChecklistItemInstance = (instanceId, projectId) => {
  let instance = null;
  if (platformActions.app.getPlatform() === 'web') {
    const lokiQuery = { projectId, id: instanceId };
    const checklistInstances = lokiInstance
      .getCollection(CHECKLIST_ITEM_INSTANCES_COLLECTION_NAME)
      .cementoFind(lokiQuery);

    instance = checklistInstances[0];
  } else {
    let instances = realmInstance.checklistItemsInstances
      .objects('checklistItemsInstance1')
      .filtered('projectId = "' + projectId + '" AND id = "' + instanceId + '"');
    if (instances.length) instance = instances[0];
  }

  return instance;
};

const handleSdkError = async ({ error, projectId, instances }) => {
  let shouldSaveLocally = true;

  const instanceIds = instances.map((instance) => instance.id);

  const _removeLocalInstances = async () => {
    shouldSaveLocally = false;
    await removeLocalInstances(instanceIds);
  };

  switch (error.code) {
    case ERROR_CODES.BAD_REQUEST:
    case ERROR_CODES.UNAUTHORIZED:
    case ERROR_CODES.PAYMENT_REQUIRED:
    case ERROR_CODES.FORBIDDEN:
    case ERROR_CODES.NOT_FOUND:
    case ERROR_CODES.METHOD_NOT_ALLOWED:
    case ERROR_CODES.NOT_ACCEPTABLE:
    case ERROR_CODES.PROXY_AUTHENTICATION_REQUIRED:
    case ERROR_CODES.CONFLICT:
      try {
        const originalChecklistItemInstances = await serverSDK.checklists.getChecklistItemInstances({
          ids: instanceIds,
          projectId,
        });

        for (const instance of _.values(originalChecklistItemInstances)) {
          if (!bouncerCollector[projectId]) bouncerCollector[projectId] = {};
          bouncerCollector[projectId][instance.id] = instance;
        }

        await _removeLocalInstances();
        setChecklistInstanceValues({ projectId });
      } catch (e) {
        if (e.code === ERROR_CODES.NOT_FOUND) await _removeLocalInstances();
        console.log('There are no original item instances');
      } finally {
        onError({
          errorMessage: 'Failed to upload local objects',
          errorCode: error.code,
          error,
          methodMetaData: { projectId, instances },
          errorMetaData: { originalErrorMessage: error.message },
        });
      }
      break;
  }

  return shouldSaveLocally;
};

const removeLocalInstances = async (instancesIds) => {
  if (platformActions.app.getPlatform() === 'web') {
    lokiInstance
      .getCollection(CHECKLIST_ITEM_INSTANCES_COLLECTION_NAME)
      .cementoDelete({ projectId, id: { '$in': instancesIds } });
  } else {
    let realmRetry = realmInstance.retry_checklistItemsInstances;
    try {
      let retryInstances = realmRetry
        .objects('checklistItemsInstance1')
        .filtered(`id IN {"${instancesIds.join('", "')}"}`);
      await realmRetry.beginTransactionMutex();
      realmRetry.delete(retryInstances);
      realmRetry.commitTransactionMutex();
    } catch (e) {
      realmRetry.cancelTransactionMutex();
    }

    let realm = realmInstance.checklistItemsInstances;
    try {
      let realmInstances = realm.objects('checklistItemsInstance1').filtered(`id IN {"${instancesIds.join('", "')}"}`);
      await realm.beginTransactionMutex();
      realm.delete(realmInstances);
      realm.commitTransactionMutex();
    } catch (e) {
      realm.cancelTransactionMutex();
    }
  }
};

const prepareDebouncingSave = ({ checklistItemsInstances, projectId, immediateSave, cleanAll, forceLocalSave }) => {
  if (!cleanAll && Object.values(checklistItemsInstances || {}).length === 0) return;

  if (!bouncerCollector[projectId]) bouncerCollector[projectId] = {};
  Object.values(checklistItemsInstances || {}).forEach((x) => {
    bouncerCollector[projectId][x.id] = x;
  });

  const setChecklistInstanceValuesParams = {
    projectId,
    cleanAll,
    forceLocalSave,
  };

  if (immediateSave) setChecklistInstanceValues(setChecklistInstanceValuesParams);
  else setChecklistInstanceValuesDebounced(setChecklistInstanceValuesParams);

  return;
};

const getLastUpdateTS = (realmInstance, lokiInstance, scopeId, dataFromApi) => {
  let lastUpdateTS = 0;

  if (platformActions.app.getPlatform() === 'web') {
    Object.values(dataFromApi || {}).forEach((x) => {
      if (x && x.updatedTS && x.updatedTS > lastUpdateTS) lastUpdateTS = x.updatedTS;
    });

    const lokiQuery = { projectId: scopeId };
    const checklistInstances = lokiInstance
      .getCollection(CHECKLIST_ITEM_INSTANCES_COLLECTION_NAME)
      .cementoFind(lokiQuery);

    _.forEach(checklistInstances, (checklist) => {
      _.forEach(checklist, (checklistItem) => {
        _.forEach(checklistItem, (instance) => {
          if (instance && instance.updatedTS > lastUpdateTS) lastUpdateTS = instance.updatedTS;
        });
      });
    });
  } else {
    lastUpdateTS = realmInstance.checklistItemsInstances
      .objects('checklistItemsInstance1')
      .filtered(`projectId = "${scopeId}"`)
      .max('updatedTS');
  }
  return lastUpdateTS || 0;
};

const saveToRealm = async ({ checklistItemsInstances, projectId, cleanAll, forceLocalSave }) => {
  if (!cleanAll && (checklistItemsInstances || []).length === 0) return;

  checklistItemsInstances = (checklistItemsInstances || []).sort((checklistItemsInstanceA, checklistItemsInstanceB) =>
    (checklistItemsInstanceA.updatedTS || 0) > (checklistItemsInstanceB.updatedTS || 0) ? -1 : 1
  );

  let realm = realmInstance.checklistItemsInstances;
  let currBatchMaxLastUpdateTS = _.get(checklistItemsInstances, [0, 'updatedTS'], 0);
  checklistItemsInstances = checklistItemsInstances.map((itemsInstance) => {
    let extraData = itemsInstance.extraData
      ? Object.values(itemsInstance.extraData).filter((item) => item.id && item.instanceId)
      : [];
    return Object.assign({}, itemsInstance, { extraData });
  });

  updateRetryRealm(
    checklistItemsInstances,
    projectId,
    realmInstance.retry_checklistItemsInstances,
    'checklistItemsInstance1'
  );
  let ignoreList = {};
  if (!forceLocalSave && checklistItemsInstances.length === 1) {
    checklistItemsInstances.forEach((inChecklistItemsInstance) => {
      let savedInstances = realm
        .objects('checklistItemsInstance1')
        .filtered(`projectId = "${projectId}" AND id = "${inChecklistItemsInstance.id}"`);
      if (savedInstances.length === 1) {
        let savedInstance = savedInstances[0].realmToObject();

        if (
          !inChecklistItemsInstance.updatedTS ||
          (Boolean(inChecklistItemsInstance.isLocal) === Boolean(savedInstance.isLocal) &&
            savedInstance.updatedTS &&
            savedInstance.updatedTS.getTime &&
            inChecklistItemsInstance.updatedTS === savedInstance.updatedTS.getTime())
        )
          ignoreList[inChecklistItemsInstance.id] = true;
      }
    });
  }

  if (Object.keys(ignoreList || {}).length < checklistItemsInstances.length) {
    await realm.beginTransactionMutex();
    try {
      if (cleanAll) {
        let allChecklistInstances = realm.objects('checklistItemsInstance1').filtered(`projectId = "${projectId}"`);
        realm.delete(allChecklistInstances);
      }
      const deletedIdList = [];

      checklistItemsInstances.forEach((checklistItemsInstance) => {
        if (ignoreList[checklistItemsInstance.id]) return;

        if (checklistItemsInstance && checklistItemsInstance.id && checklistItemsInstance.checklistItemId) {
          if (!checklistItemsInstance.isDeleted) {
            let newObj = {
              ...checklistItemsInstance.realmToObject(),
              isLocal: Boolean(checklistItemsInstance.isLocal),
              projectId,
            };
            realm.create('checklistItemsInstance1', newObj, 'modified');
          } else {
            deletedIdList.push(checklistItemsInstance.id);
          }
        } else {
          console.warn('checklistItemsInstance missing ID');
          platformActions.sentry.notify('checklistItemsInstance missing ID', {
            ...(checklistItemsInstance || {}).realmToObject(),
          });
        }
      });

      if (deletedIdList?.length) {
        try {
          const allDeletedInstances = realm
            .objects('checklistItemsInstance1')
            .filtered(`projectId = "${projectId}" AND id IN {"${deletedIdList.join('", "')}"}`);

          realm.delete(allDeletedInstances);
        } catch (error) {
          console.log('____ERRROR', error);
        }
      }
      replaceMaxUpdateTSIfNeeded(
        currBatchMaxLastUpdateTS,
        realm,
        'checklistItemsInstance1',
        `projectId = "${projectId}"`
      );
      realm.commitTransactionMutex();
    } catch (e) {
      console.log('TCL: STILL ERROR!!!!');
      realm.cancelTransactionMutex();
      throw e;
    }
  }
};

const setChecklistInstanceValues = ({ projectId, cleanAll, forceLocalSave }) => {
  let checklistItemsInstances = [];
  let cleanedFromBouncer = [];
  if (!bouncerCollector[projectId]) bouncerCollector[projectId] = {};

  Object.keys(bouncerCollector[projectId]).forEach((key) => {
    checklistItemsInstances.push(bouncerCollector[projectId][key]);
    cleanedFromBouncer.push(key);
  });

  cleanedFromBouncer.forEach((key) => {
    delete bouncerCollector[projectId][key];
  });

  if (platformActions.app.getPlatform() != 'web') {
    const saveToRealmParams = {
      checklistItemsInstances,
      projectId,
      cleanAll,
      forceLocalSave,
    };
    const saveToRealmFunc = () => saveToRealm(saveToRealmParams);
    const notificationMessage = 'saveToRealm ~ The function took too long';
    const notificationLabel = 'checklistItemsInstances';
    notifyLongFunctionDuration(saveToRealmFunc, notificationMessage, notificationLabel);
  } else {
    const checklistItemsInstancesWithProjectId = checklistItemsInstances.map((item) => ({ ...item, projectId }));
    lokiInstance
      .getCollection(CHECKLIST_ITEM_INSTANCES_COLLECTION_NAME)
      .cementoUpsert(checklistItemsInstancesWithProjectId);
  }
};

const setChecklistInstanceValuesDebounced = AwesomeDebouncePromise(setChecklistInstanceValues, 400);
