import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import { getLocationTitle } from "../Locations/funcs";
import { startLoading, hideLoading } from "../../../common/app/actions";
import { saveChecklistForm } from "../../../common/checklists/actions";
import { exportFormPDF } from "../../../common/pdf/actions";
import _ from "lodash";

import { GridItem, GridContainer } from "../../components";
import Checklists from "./Checklists.js";
import MinPost from "../Posts/MinPost.js";
import * as issueStates from "../../../common/issues/issueStates";
import checklistItemMessages from "../../../common/checklistItems/checklistItemMessages";
import checklistMessages from "../../../common/checklists/checklistsMessages";
import theme from "../../assets/css/theme";
import MenuScrollbar from "../../components/CementoComponents/MenuScrollbar";
import Text from "../../components/CementoComponents/Text";
import ImageCarousel from "../../components/CementoComponents/ImageCarousel";
import { onDraftModeChange } from "../../../common/ui/actions.js";
import ChecklistSignModal from "./ChecklistSignModal.js";
import isEqual from 'react-fast-compare';

const QA_FORM_TEMPLATE_ID = "-LrE_NOhw1r-pk-zT7cY"; //Quality assurance form template id

class ChecklistCard extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.getWantedLocationsData = this.getWantedLocationsData.bind(this);
    this.handleInnerObjectSelect = this.handleInnerObjectSelect.bind(this);
    this.relevantPostsHandler = this.relevantPostsHandler.bind(this);
    this.onInnerObjectSelect = this.onInnerObjectSelect.bind(this);
    this.onCardTabSelect = this.onCardTabSelect.bind(this);
    this.onExportChecklist = this.onExportChecklist.bind(this);
    this.cancelExtraDataChanges = this.cancelExtraDataChanges.bind(this);
    this.handleChecklistRowRef = this.handleChecklistRowRef.bind(this);
    this.displaySignModal = this.displaySignModal.bind(this);
    this.handleChecklistSigned = this.handleChecklistSigned.bind(this);
    this.setHasChanges = this.setHasChanges.bind(this);
    this.checklistRowRefs = {};
    this.state = {
      signModalProps: {},
      shouldDisplaySignModal: false,
      relevantIssues: [],
      relevantDocumentations: [],
      hasChanges: false
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  shouldComponentUpdate(nextProps, nextState) {
    let prevSelectedChecklists = Object.keys(
      this.props.selectedChecklists || {}
    );
    let nextSelectedChecklists = Object.keys(
      nextProps.selectedChecklists || {}
    );
    let prevSelectedChecklistItems = Object.keys(
      this.props.selectedChecklistItems || {}
    );
    let nextSelectedChecklistItems = Object.keys(
      nextProps.selectedChecklistItems || {}
    );

    let should =
      prevSelectedChecklists.length != nextSelectedChecklists.length ||
      _.difference(prevSelectedChecklists, nextSelectedChecklists).length > 0 ||
      prevSelectedChecklistItems.length != nextSelectedChecklistItems.length ||
      _.difference(prevSelectedChecklistItems, nextSelectedChecklistItems)
        .length > 0 ||
      this.props.isValDiff(nextProps, ["type"]) ||
      this.props.isValDiff(nextProps, ["locationId"]) ||
      this.props.isValDiff(nextProps, ["locationsData"]) ||
      this.props.isValDiff(nextProps, ["stage"]) ||
      this.state.pdfDisplay != nextState.pdfDisplay ||
      this.props.editMode != nextProps.editMode ||
      this.state.selectedTabId != nextState.selectedTabId ||
      !_.isEqual(this.state.relevantIssues, nextState.relevantIssues) ||
      !_.isEqual(
        this.state.relevantDocumentations,
        nextState.relevantDocumentations
      ) || this.state.shouldDisplaySignModal != nextState.shouldDisplaySignModal;
    return should;
  }

  handleInnerObjectSelect(selectedStage, checklist, checklistItem, locationId) {
    const { onInnerObjectSelect } = this.props;
    if (onInnerObjectSelect && checklistItem)
      onInnerObjectSelect("checklistItem", {
        checklist,
        checklistItem,
        locationId,
      });
  }

  cancelExtraDataChanges() {
    Object.values(this.checklistRowRefs || {})
      .forEach(ref => {
        ref?.component?.cancelExtraDataChanges?.();
      });
  }

  handleChecklistRowRef(componentId, component) {
    this.checklistRowRefs[componentId] = { component };
  }


  setComponentData(props, nextProps) {
    const { firstMount } = props;
    let newStateChanges = {};
    if (
      (props.isValDiff(nextProps, ["buildings"]) ||
        props.isValDiff(nextProps, ["floors"]) ||
        props.isValDiff(nextProps, ["units"]) ||
        props.isValDiff(nextProps, ["locationsData"])) &&
      nextProps.locationsData
    ) {
      let locationsData = nextProps.locationsData;
      let locationDataId = locationsData[locationsData.type + "Id"];
      newStateChanges.locationId = locationDataId;
      newStateChanges.aggregatedLocationsData = this.getWantedLocationsData(
        nextProps,
        nextProps.locationsData
      );
      let singleBuildingProject =
        nextProps.getNested(["buildings"], {}).size < 2;
      newStateChanges.currLocationTitle = getLocationTitle(
        nextProps.buildings,
        nextProps.floors,
        nextProps.units,
        null,
        null,
        null,
        locationDataId,
        nextProps.intl,
        singleBuildingProject
      );
    }
    if (props.isValDiff(nextProps, ["locationsData"]))
      newStateChanges.selectedPost = null;
    if (
      props.selectedLayer != nextProps.selectedLayer &&
      nextProps.selectedLayer == 0
    )
      newStateChanges.selectedPost = null;
    
    let hasDifferentKeys = !isEqual(props.selectedChecklists, nextProps.selectedChecklists);
    
    if (
      hasDifferentKeys
    ) {
      const { selectedChecklists, selectedChecklistItems, checklistItems } =
        nextProps;
      let items = selectedChecklistItems
        ? Object.keys(selectedChecklistItems)
        : [];
      if (!selectedChecklistItems)
        checklistItems.loopEach((checklistItemId, checklistItem) => {
          (checklistItem.checklistIds || {}).loopEach((key, { id }) => {
            if (selectedChecklists[id]) items.push(checklistItemId);
          });
        });
    }

    let didObjectChanged = Boolean(
      newStateChanges.locationId ||
        newStateChanges.aggregatedLocationsData ||
        newStateChanges.currLocationTitle ||
        newStateChanges.selectedPost ||
        this.props.isValDiff(nextProps, ["checklist", "id"])
    );

    if (Object.keys(newStateChanges).length > 0  || didObjectChanged)
      this.setState(newStateChanges, () => {
        if (didObjectChanged) this.handleCardLoad(false);
      });
  }
  componentDidUpdate(prevProps){
      const { onDraftModeChange } = this.props;
      if (prevProps.editMode !== this.props.editMode) {
        onDraftModeChange(Boolean(this.props.editMode));
      }
  }

  async handleChecklistSigned(uploadedSignatures) {
    const {
      selectedProjectId,
      locationsData,
      checklist,
      selectedCell,
      configurations,
      viewer,
      saveChecklistForm,
      projects,
      exportFormPDF,
    } = this.props;
    const project = projects.get(selectedProjectId);
    const location = locationsData;
    const selectedChecklistId =
      _.get(checklist, ["id"]) ||
      _.get(selectedCell, ["cell", "column", "original", "checklistId"]);

    const formTemplateId = Object.values(configurations.forms).find(form => form.exportableChecklists?.[selectedChecklistId])?.id
    let formType = (configurations || {}).getNested(
      ["forms", formTemplateId, "type"],
      "general"
    );
    this.setState({ shouldDisplaySignModal: false });

    let form = await saveChecklistForm(
      viewer,
      selectedProjectId,
      selectedChecklistId,
      location,
      configurations.getNested(["forms", formTemplateId]),
      uploadedSignatures,
      formType
    );
    
    let pdf = await exportFormPDF({
      viewer,
      project,
      formId: form.id,
      formType: "general",
      isListenerMode: true,
    });
    let pdfDisplay = null;
    if (pdf?.uri?.startsWith?.("http")) pdfDisplay = pdf.uri;

    this.setState({ pdfDisplay });
  }
  async displaySignModal() {
    const {
      selectedProjectId,
      locationsData,
      checklist,
      selectedCell,
      configurations,
      viewer,
      saveChecklistForm,
    } = this.props;
    let location = locationsData;
    const selectedChecklistId =
      _.get(checklist, ["id"]) ||
      _.get(selectedCell, ["cell", "column", "original", "checklistId"]);

    const formTemplateId = Object.values(configurations.forms).find(form => form.exportableChecklists?.[selectedChecklistId])?.id
    let formBusinessType = (configurations || {}).getNested(
      ["forms", formTemplateId, "type"],
      "general"
    );
    let form = await saveChecklistForm(
      viewer,
      selectedProjectId,
      selectedChecklistId,
      location,
      configurations.getNested(["forms", formTemplateId]),
      null,
      formBusinessType
    );
    
    this.setState({ 
      shouldDisplaySignModal: true,
      signModalProps: {
        currentForm: form,
        formConfigurationsSignatures: configurations.getNested(["forms", formTemplateId, "signatures"]),
      },

    });
  }

  handleCardLoad(isEditMode) {
    const { currLocationTitle, aggregatedLocationsData } = this.state;
    const {
      onCardLoad,
      selectedChecklists,
      selectedChecklistItems,
      locationsData,
      alert,
      changeEditMode,
      checklist,
      selectedCell,
      configurations,
    } = this.props;

    if (onCardLoad) {
      let editMode = isEditMode
      let headerOptions = {
        alert,
        editable: true,
        title: Object.values(currLocationTitle).join(" / "),
        // sideCard component expects return value from onSave in order to change the edit mode. When we change status of checklistItem it's update it directly, so when we save we don't really need to do something.
        onSave: () => true,
        onCancel: this.state.hasChanges ? () => {
          this.cancelExtraDataChanges();
          if (changeEditMode)
            changeEditMode(false);
          return true;
        } : null
      };

      const selectedChecklistId =
      _.get(checklist, ["id"]) ||
      _.get(selectedCell, ["cell", "column", "original", "checklistId"]);
      const formTemplateId = Object.values(configurations.forms).find(form => form.exportableChecklists?.[selectedChecklistId])?.id
      if(formTemplateId) {
        headerOptions.onSign = this.displaySignModal;
      }
      let shouldShowPrintIcon = false;
      let specificChecklistSelected =
        selectedChecklists &&
        Object.keys(selectedChecklists).length == 1 &&
        Object.keys(selectedChecklistItems || {}).length == 0
          ? Object.keys(selectedChecklists)[0]
          : null;

      if (specificChecklistSelected) {
        if (aggregatedLocationsData.length == 1) shouldShowPrintIcon = true;
        else if (locationsData.type == "floor")
          // Check for floor
          shouldShowPrintIcon = Boolean(
            this.props.checklists.getNested([
              Object.keys(selectedChecklists)[0],
              "locations",
              "floors",
              locationsData["floorId"],
            ])
          );
        else if (locationsData.type == "building")
          // Check for building
          shouldShowPrintIcon = Boolean(
            this.props.checklists.getNested([
              Object.keys(selectedChecklists)[0],
              "locations",
              "buildings",
              locationsData["buildingId"],
            ])
          );
      }
      
      if (shouldShowPrintIcon)
        headerOptions.onPrint = () => this.onExportChecklist();

      let tabsParams = {
        onTabSelect: this.onCardTabSelect,
        tabs: [
          { href: "info", title: checklistMessages.checklistCard.info },
          {
            href: "relevantIssues",
            title: checklistMessages.checklistCard.connectedTask,
          },
          {
            href: "relevantDocumentations",
            title: checklistMessages.checklistCard.records,
          },
        ],
      };
      onCardLoad(headerOptions, tabsParams, editMode);
    }
  }

  async onExportChecklist() {
    const {
      exportFormPDF,
      viewer,
      selectedProjectId,
      projects,
      configurations,
      saveChecklistForm,
      locationsData,
      checklist,
      selectedCell,
    } = this.props;
    
    let project = projects.get(selectedProjectId);
    let location = locationsData;
    const selectedChecklistId =
      _.get(checklist, ["id"]) ||
      _.get(selectedCell, ["cell", "column", "original", "checklistId"]);
    if (selectedChecklistId) {
      let pdfDisplay = null;

      let formBusinessType = (configurations || {}).getNested(
        ["forms", QA_FORM_TEMPLATE_ID, "type"],
        "general"
      );
      let form = await saveChecklistForm(
        viewer,
        selectedProjectId,
        selectedChecklistId,
        location,
        configurations.getNested(["forms", QA_FORM_TEMPLATE_ID]),
        null,
        formBusinessType
      );

      let pdf = await exportFormPDF({
        viewer,
        project,
        formId: form.id,
        formType: "general",
        isListenerMode: true,
      });

      if (pdf && pdf.uri && pdf.uri.startsWith("http")) pdfDisplay = pdf.uri;

      this.setState({ pdfDisplay });
    }
  }

  hidePdfDisplay = () => this.setState({ pdfDisplay: null });

  relevantPostsHandler(posts) {
    let relevantIssues = [];
    let relevantDocumentations = [];
    posts = posts.sort(
      (a, b) =>
        (b.editedAt || b.createdAt || 0) - (a.editedAt || a.createdAt || 0)
    );
    posts.forEach((p) =>
      !p.isIssue || p.issueState == issueStates.ISSUE_STATE_CLOSED
        ? relevantDocumentations.push(p)
        : relevantIssues.push(p)
    );
    this.setState({ relevantIssues, relevantDocumentations });
  }

  getWantedLocationsData(nextProps, locationsData) {
    const { selectedProjectId } = nextProps;
    let locationDataType = locationsData.type + "s";
    let locationDataId = locationsData[locationsData.type + "Id"];
    let buildingId = locationDataType == "buildings" ? locationDataId : null;
    if (!buildingId)
      nextProps.getNested([locationDataType], {}).loopEach((bid, building) =>
        building.loopEach((id) => {
          if (id == locationDataId) buildingId = bid;
        })
      );
    let currLocationTitle = getLocationTitle(
      nextProps.buildings,
      nextProps.floors,
      nextProps.units,
      buildingId,
      locationDataType == "floors" ? locationDataId : null,
      locationDataType == "units" ? locationDataId : null,
      null,
      nextProps.intl
    );
    currLocationTitle =
      locationDataType == "buildings"
        ? currLocationTitle.buildingTitle
        : locationDataType == "floors"
        ? currLocationTitle.floorTitle
        : currLocationTitle.unitTitle;
    let arr = [
      { type: locationDataType, id: locationDataId, title: currLocationTitle },
    ];

    if (locationDataType == "buildings") {
      let floorsTitleByNum = {};
      let floorsArray = nextProps.getNested(["floors", locationDataId], {});
      let unitsArray = nextProps.getNested(["units", locationDataId], {});
      floorsArray = Object.values(
        floorsArray.toJS ? floorsArray.toJS() : floorsArray
      );
      unitsArray = Object.values(
        unitsArray.toJS ? unitsArray.toJS() : unitsArray
      );
      floorsArray
        .sort((a, b) => a.num - b.num)
        .forEach((floor) => {
          let title = getLocationTitle(
            nextProps.buildings,
            nextProps.floors,
            nextProps.units,
            locationDataId,
            floor.id,
            null,
            null,
            nextProps.intl
          ).floorTitle;
          arr.push({ type: "floors", id: floor.id, title });
          floorsTitleByNum[floor.num] = title;
        });
      unitsArray
      .sort((a, b) => a.ordinalNo - b.ordinalNo)
      .forEach((unit) => {
        let title = getLocationTitle(
          nextProps.buildings,
          nextProps.floors,
          nextProps.units,
          locationDataId,
          null,
          unit.id,
          null,
          nextProps.intl
        ).unitTitle;
        arr.push({
          type: "units",
          id: unit.id,
          title:
            (floorsTitleByNum[unit.getNested(["floor", "num"])] || "") +
            " - " +
            title,
        });
      });
    } else if (locationDataType == "floors") {
      let floor = nextProps.getNested(
        ["floors", buildingId, locationDataId],
        {}
      );
      nextProps.getNested(["units", buildingId], {}).loopEach((id, unit) => {
        if (
          floor.num != null &&
          floor.num != undefined &&
          unit.floor &&
          unit.floor.num == floor.num
        ) {
          let title = getLocationTitle(
            nextProps.buildings,
            nextProps.floors,
            nextProps.units,
            buildingId,
            floor.id,
            id,
            null,
            nextProps.intl
          ).unitTitle;
          arr.push({ type: "units", id: id, title });
        }
      });
    }

    return arr;
  }

  onInnerObjectSelect(post) {
    const { onInnerObjectSelect } = this.props;
    if (onInnerObjectSelect) onInnerObjectSelect("post", { post });
  }

  onCardTabSelect(id) {
    this.setState({ selectedTabId: id });
    setTimeout(
      (() => {
        this.setState({ selectedTabId: null });
      }).bind(this),
      500
    );
  }

  setHasChanges = (newHasChanges) => {
    const { editMode } = this.props
    const { hasChanges } = this.state;

    this.setState({ hasChanges: newHasChanges }, () => {
      if (hasChanges !== newHasChanges) {
        this.handleCardLoad(editMode)
      }
    });
  }

  render() {
    const {
      rtl,
      trades,
      classes,
      uiParams,
      checklists,
      editMode,
      changeEditMode,
      selectedChecklists,
      selectedChecklistItems,
      isAggregatedCell,
    } = this.props;
    const { aggregatedLocationsData, selectedTabId, pdfDisplay, shouldDisplaySignModal, signModalProps } = this.state;
    const { buildingId, floorId, unitId, type } = this.props.getNested(
      ["locationsData"],
      {}
    );

    let singleChecklistId =
      Object.keys(selectedChecklists).length == 1
        ? Object.keys(selectedChecklists)[0]
        : null;
    let extraInfoText = checklists.getNested([
      singleChecklistId,
      "locations",
      `${type}s`,
      unitId || floorId || buildingId,
      "extraInfo",
      "text",
    ]);

    let shouldDisplayPdf = Boolean(pdfDisplay && !_.isNil(pdfDisplay));

    let headerTextStyle = {
      ...theme.subFont,
      color: "#2e231d",
      fontSize: theme.mediumFontSize,
      fontWeight: theme.strongBold,
      lineHeight: theme.mediumFontSize + "px",
      width: "auto",
      [rtl ? "marginLeft" : "marginRight"]: theme.paddingSize,
    };
    let headerSectionStyle = {
      alignItems: "center",
      textAlign: "center",
      marginBottom: theme.paddingSize,
      color: theme.headerColorDark,
      fontFamily: "Assistant - Semi Bold",
      fontSize: 16,
      fontWeight: theme.bold,
      [rtl ? "marginLeft" : "marginRight"]: 3,
    };

    let size = uiParams.getNested(["screenBootstrapWidth"]);
    let maxChars = size == "xl" ? 75 : 60;

    return (
      <MenuScrollbar isSmooth={true}>
        <div
          id="info"
          className={classes.cardSectionsStyles}
          style={{
            transition: "all 150ms ease 0s",
            backgroundColor:
              selectedTabId == "info"
                ? theme.backgroundColorHover
                : theme.backgroundColorBright,
          }}
        >
          {Boolean(shouldDisplaySignModal) &&
            <ChecklistSignModal
              handleClose={() => this.setState({ shouldDisplaySignModal: false })}
              handleDone={this.handleChecklistSigned}
              {...signModalProps}
              isOpen={shouldDisplaySignModal}
            />
          }
          <Checklists
            handleChecklistRowRef={this.handleChecklistRowRef}
            isAggregated={isAggregatedCell}
            hideStage={true}
            minMode={true}
            enableCollectiveAction={editMode}
            maxCharsInItem={maxChars}
            stageTitleCentered={true}
            openAll={editMode}
            relevantPostsHandler={this.relevantPostsHandler}
            locationsData={aggregatedLocationsData}
            filteredChecklists={selectedChecklists}
            filteredChecklistsItem={selectedChecklistItems}
            onClick={this.handleInnerObjectSelect}
            extraDataEditMode={editMode}
            toggleExtraDataEditMode={changeEditMode}
            setHasChanges={this.setHasChanges}
          />
        </div>

        {Boolean(extraInfoText) && (
          <div
            id={"extraInfo"}
            className={classes.cardSectionsStyles}
            style={{
              transition: "all 150ms ease 0s",
              backgroundColor:
                selectedTabId == "extraInfo"
                  ? theme.backgroundColorHover
                  : theme.backgroundColorBright,
            }}
          >
            <Text style={headerSectionStyle}>
              {checklistItemMessages.extraDescription}
            </Text>
            <GridContainer spacing={8}>
              <GridItem xs={12}>
                <Text linksMode={"convert"}>{extraInfoText}</Text>
              </GridItem>
            </GridContainer>
          </div>
        )}

        {["relevantIssues", "relevantDocumentations"].map((listName) => (
          <div
            id={listName}
            className={classes.cardSectionsStyles}
            style={{
              transition: "all 150ms ease 0s",
              backgroundColor:
                selectedTabId == listName
                  ? theme.backgroundColorHover
                  : theme.backgroundColorBright,
            }}
          >
            <Text style={headerSectionStyle}>
              {checklistItemMessages[listName]}
            </Text>
            <GridContainer spacing={8}>
              {this.state[listName].length ? (
                this.state[listName].map((p) => (
                  <GridItem
                    xs={12}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <MinPost
                      postHeight={null}
                      //itemsMode={!p.isIssue ? ""}
                      onSelect={this.onInnerObjectSelect}
                      post={p}
                      viewMode={"blocks"}
                      trades={trades}
                      minMode={true}
                    />
                  </GridItem>
                ))
              ) : (
                <GridItem xs={12}>
                  <Text>{checklistItemMessages[listName + "Empty"]}</Text>
                </GridItem>
              )}
            </GridContainer>
          </div>
        ))}
        {shouldDisplayPdf && (
          <ImageCarousel
            items={[{ src: pdfDisplay }]}
            pdfMode={true}
            onClose={this.hidePdfDisplay}
            toolbar={true}
          />
        )}
      </MenuScrollbar>
    );
  }
}

let styles = {
  postImageSize: 160,
  cardSectionsStyles: {
    padding: theme.paddingSize + "px " + 2 * theme.paddingSize + "px",
    boxShadow: "rgba(0, 0, 0, 0.08) 0px 1px 20px 0px",
    marginBottom: theme.paddingSize,
    display: "flex",
    flexDirection: "column",
  },
};

ChecklistCard = injectIntl(ChecklistCard);
ChecklistCard = withStyles(styles)(ChecklistCard);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      uiParams: state.ui.uiParams,
      lang: state.app.lang,
      rtl: state.app.rtl,
      trades: state.trades.map,
    }),
    { startLoading, hideLoading, exportFormPDF, saveChecklistForm, onDraftModeChange }
  )
);
export default enhance(ChecklistCard);
